import { FC, HTMLAttributes } from 'react'
import { DayPicker as ReactDayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { DayPickerProps } from '@/root/shared-types'
import * as R from 'ramda'
import cn from 'classnames'
import { useConfig } from '@/root/shared-hooks'
import { CustomNavbar } from './admaritime/CustomNavBar'
import bluewaveStyles from '@/root/components/shared/DayPicker/bluewave/styles.module.css'
import admaritimeStyles from '@/root/components/shared/DayPicker/admaritime/styles.module.css'
import { Head as BluewaveHead } from '@/root/components/shared/DayPicker/bluewave/CustomWeekBar'
import { Head as AdmaritimeHead } from '@/root/components/shared/DayPicker/admaritime/CustomWeekBar'
import { es } from 'date-fns/locale'
import { enUS } from 'date-fns/locale'
import { ar } from 'date-fns/locale'
import { useRouter } from 'next/router'

const arabicLocale = {
  ...ar,
  localize: {
    ...ar.localize,
    day: (narrowDay: number) => {
      const shortDays = ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س']
      return shortDays[narrowDay]
    },
  },
}

const DayPicker: FC<HTMLAttributes<HTMLSpanElement> & DayPickerProps> = ({
  selectedDate,
  onDayClick,
  isDayDisabled,
  className,
  children,
  isCustomStyle = false,
}) => {
  const { theme } = useConfig()
  const { locale } = useRouter()

  const styles = theme === 'bluewave' ? bluewaveStyles : admaritimeStyles
  const HeadComponent = theme === 'bluewave' ? BluewaveHead : AdmaritimeHead
  const localeData = locale === 'es' ? es : locale === 'ar' ? arabicLocale : enUS

  const defaultClasses = cn(
    'border-neutral-normal-lighter flex h-[344px] justify-center rounded-lg border pt-5 text-lg',
    {
      'w-screen p-2 xxs:w-[380px] xxs:p-4 xxs:pt-3 lg:w-[372px] lg:ps-2 lg:pt-4 border-none font-normal':
        isCustomStyle === true,
    }
  )

  return (
    <span data-testid="departureForm" className={className}>
      {children}

      <ReactDayPicker
        data-testid="calendar-wrapper"
        className={`${defaultClasses} !m-0`}
        selected={selectedDate}
        onDayClick={onDayClick}
        disabled={isDayDisabled}
        weekStartsOn={1}
        modifiers={{
          allDays: R.T(),
        }}
        modifiersClassNames={{
          selected: styles.rdpDaySelected,
          today: styles.rdpDayToday,
          allDays: styles.rdpDayCommon,
        }}
        defaultMonth={selectedDate}
        components={{
          Caption: CustomNavbar,
          Head: HeadComponent,
        }}
        dir="ltr"
        locale={localeData}
      />
    </span>
  )
}

export default DayPicker
