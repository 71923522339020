import { FC, PropsWithChildren, useEffect, useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Link from 'next/link'
import CookiesBanner from '@/root/ui/admaritime/Cookies/CookiesBanner'
import { globalFont } from '@/root/modules/admaritime/AppLayout/assets/globalFonts/globalFonts'
import { Modal } from '@/root/components/admaritime/Modal/Modal'
import { useModal } from '@/root/shared-hooks'
import { Drawer } from '@/root/components/admaritime'
import { useDrawer } from '@/root/shared-hooks/useDrawer/useDrawer'
import useCookieConsent from '@/root/shared-hooks/useCookieConsent/useCookieConsent'
import { useGetTranslations } from '@/root/shared-hooks'
import CookiesTextField from '@/root/ui/admaritime/Cookies/CookiesTextfield'
import { DayPickerProvider } from 'react-day-picker'

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const { setSemiOpened, semiOpened, drawerBody } = useDrawer()
  const { getTranslation } = useGetTranslations()
  const { consent, setConsent, isInitialized } = useCookieConsent()
  const { modalBody, onClose } = useModal()

  const [hostname, setHostname] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHostname(window.location.hostname)
    }
  }, [])

  const cookiesMessage = getTranslation('cookiesCollect', { url: hostname })
  const privacyPolicyText = getTranslation('privacyPolicy')

  const privacyPolicyLink = (
    <Link href="/content/privacypolicy/" className="cursor-pointer underline">
      {privacyPolicyText}
    </Link>
  )

  return (
    <DayPickerProvider initialProps={{ mode: 'single' }}>
      <div className={`${globalFont.className} flex h-screen flex-col`}>
        {!consent && isInitialized && (
          <CookiesBanner
            textField={<CookiesTextField cookiesMessage={cookiesMessage} privacyPolicyLink={privacyPolicyLink} />}
            onAgree={setConsent}
          />
        )}
        <Header />
        <main className="bg-neutral-very-light grow">{children}</main>
        <Modal isOpen={Boolean(modalBody)} onClose={onClose}>
          {modalBody}
        </Modal>
        <Drawer body={drawerBody} isOpen={Boolean(drawerBody)} semiOpened={semiOpened} setSemiOpened={setSemiOpened} />
        <Footer />
      </div>
    </DayPickerProvider>
  )
}

export default AppLayout
