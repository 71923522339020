import { Dispatch, FC, SetStateAction } from 'react'
import { FilterCell } from '@/root/modules/admaritime/CustomerInformation'
import { Checkbox } from '@/root/ui/admaritime'
import { useGetTranslations } from '@/root/shared-hooks'

interface PreviousTicketProps {
    previousETicketsChecked: boolean
    setPreviousETicketsChecked: Dispatch<SetStateAction<boolean>>
}
  
interface CancelledTicketProps {
    cancelledETicketsChecked: boolean
    setCancelledETicketsChecked: Dispatch<SetStateAction<boolean>>
}
  
export const ReservationFilterByPreviousTicket: FC<PreviousTicketProps> = ({ 
    previousETicketsChecked, 
    setPreviousETicketsChecked,
}) => {
    const { getTranslation } = useGetTranslations()
    
    const togglePreviousETickets = () => {
        setPreviousETicketsChecked(!previousETicketsChecked)
    }
    
    return (
        <FilterCell
        label={getTranslation('ticketPrintedPreviousTicket')}
        >
            <Checkbox
            variant="success"
            checked={previousETicketsChecked}
            onChange={togglePreviousETickets}
            className='w-full'
            />
        </FilterCell>
    )
}

export const ReservationFilterByCancelledTicket: FC<CancelledTicketProps> = ({ 
    cancelledETicketsChecked,
    setCancelledETicketsChecked
}) => {
    const { getTranslation } = useGetTranslations()
    
    const toggleCancelledETickets = () => {
        setCancelledETicketsChecked(!cancelledETicketsChecked)
    }

    return (
        <FilterCell
        label={getTranslation('ticketPrintedCancelledTicket')}
        >
            <Checkbox
            variant="success"
            checked={cancelledETicketsChecked}
            onChange={toggleCancelledETickets}
            className='w-full'
            />
        </FilterCell>
    )
}