import { FC, InputHTMLAttributes } from 'react'
import { Control, FieldErrors, FieldValues, UseFormTrigger, UseFormRegister } from 'react-hook-form'
import { FormField } from '@/root/components/admaritime/CustomerFormField/FormField/FormField'
import { FormFieldContainer } from '@/root/components/admaritime/CustomerFormField/FormFieldContainer/FormFieldContainer'
import { PhoneNumberFormField } from '@/root/components/admaritime/CustomerFormField/PhoneNumberFormField/PhoneNumberFormField'
import { DateFormField } from '@/root/components/admaritime/CustomerFormField/DateFormField/DateFormField'
import { noop } from 'lodash'

interface CustomerInfoInputProps extends InputHTMLAttributes<HTMLInputElement> {
  control: Control
  register: UseFormRegister<FieldValues>
  label: string
  errors: FieldErrors
  trigger: UseFormTrigger<FieldValues>
  selectedDate?: Date
  onDayClick?(date: Date): void
  maxWidth?: 'lg' | 'full'
  isEditable?: boolean
  textLeft?: 'text-start' | 'text-end'
  onBlur?(): void
  isCustomerDetails?: boolean
}

interface Pattern {
  pattern?: RegExp
  maxLength?: number
  minLength?: number
}

const patternDefiner = (type: InputHTMLAttributes<HTMLInputElement>['type']): Pattern | undefined => {
  if (type === 'email') {
    return {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+(?=.{1,70}$)/,
      maxLength: 70,
      minLength: 1,
    }
  } else if (type === 'text') {
    return {
      pattern: new RegExp(
        [
          '^(?![&+\\-\'"/~])',
          '(?!.*[&+\\-\'"/~]{2})',
          '(?!^[0-9&+\\-\'"/~]+$)',
          '(?!^(?:[&+\\-\'"/~]*\\d+[&+\\-\'"/~]*)+$)',
          '[A-Za-zÀ-ÖØ-öø-ÿĀ-ž\\u0600-\\u06FF0-9&+\\-\'"/~]+',
          "(?:[-' ][A-Za-zÀ-ÖØ-öø-ÿĀ-ž\\u0600-\\u06FF0-9&+\\-'\"/~]+)*",
          '\\s*$',
        ].join('')
      ),
      maxLength: 160,
      minLength: 1,
    }
  }
}

export const CustomerFormField: FC<CustomerInfoInputProps> = ({
  control,
  id = 'default-date-id',
  label,
  type,
  placeholder,
  errors,
  trigger,
  onBlur,
  className,
  maxWidth,
  isEditable,
  textLeft,
  selectedDate,
  isCustomerDetails,
  onDayClick,
}) => {
  const register = control?.register || noop
  if (type === 'tel') {
    return (
      <PhoneNumberFormField
        register={register}
        label={label}
        codeId="code"
        phoneId="phoneNumber"
        errors={errors}
        trigger={trigger}
        onBlur={onBlur}
        className={className}
        maxWidth={maxWidth}
        isEditable={isEditable}
        textLeft={textLeft}
        isCustomerDetails={isCustomerDetails}
      />
    )
  }

  if (type === 'date') {
    return (
      <DateFormField
        id="dateOfBirth"
        register={register}
        label={label}
        errors={errors}
        trigger={trigger}
        onBlur={onBlur}
        className={className}
        maxWidth={maxWidth}
        textLeft={textLeft}
        selectedDate={selectedDate}
        onDayClick={onDayClick}
        isEditable={false}
        type="text"
      />
    )
  }

  return (
    <FormFieldContainer id={id} label={label} maxWidth={maxWidth} isEditable={isEditable} textLeft={textLeft}>
      <FormField
        type={type}
        id={id}
        register={register}
        placeholder={placeholder}
        errors={errors}
        trigger={trigger}
        maxLength={patternDefiner(type)?.maxLength}
        minLength={patternDefiner(type)?.minLength}
        validationPattern={patternDefiner(type)?.pattern}
        onBlur={onBlur}
        className={className}
      />
    </FormFieldContainer>
  )
}
