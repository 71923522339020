import { FC, Dispatch, SetStateAction, useState } from 'react'
import { useGetTranslations } from '@/root/shared-hooks'
import { ReservationFilterByCancelledTicket, ReservationFilterByPreviousTicket, FilterCell, ReservationFilterByDates, ReservationFilterById } from '@/root/modules/admaritime/CustomerInformation'

interface ReservationFilterProps {
  setTicketNumber: Dispatch<SetStateAction<string>>
  ticketNumber: string
  setFromDate: Dispatch<SetStateAction<Date | undefined>>
  setToDate: Dispatch<SetStateAction<Date | undefined>>
  previousETicketsChecked: boolean
  setPreviousETicketsChecked: Dispatch<SetStateAction<boolean>>
  cancelledETicketsChecked: boolean
  setCancelledETicketsChecked: Dispatch<SetStateAction<boolean>>
}

const ReservationFilter: FC<ReservationFilterProps> = ({
  setTicketNumber, 
  ticketNumber, 
  setFromDate, 
  setToDate,
  previousETicketsChecked,
  setPreviousETicketsChecked,
  cancelledETicketsChecked,
  setCancelledETicketsChecked,
 }) => {
  const { getTranslation } = useGetTranslations()

  const [fromDate, setLocalFromDate] = useState<string>()
  const [toDate, setLocalToDate] = useState<string>()

  return (
    <fieldset className="bg-neutral-lightest grid grid-cols-1 rounded-lg lg:h-24 lg:max-w-[1104px] lg:grid-cols-filter lg:grid-rows-1">
      <FilterCell
      label={getTranslation('ticketPrintedTicketNumber')}
      value={ticketNumber}
      setValue={setTicketNumber}
      >
        <ReservationFilterById setTicketNumber={setTicketNumber} ticketNumber={ticketNumber || ''} />
      </FilterCell>
      <FilterCell
        label={getTranslation('ticketPrintedTicketDate')}
        value={fromDate || toDate ? `${fromDate} - ${toDate}` : ''}
        setValue={() => {
          setLocalFromDate('')
          setLocalToDate('')
          setFromDate(undefined)
          setToDate(undefined)
        }}
      >
        <ReservationFilterByDates
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={(date) => {
          setLocalFromDate(date?.toString())
          setFromDate(date)
        }}
        setToDate={(date) => {
          setLocalToDate(date?.toString())
          setToDate(date)
        }}
        />
      </FilterCell>
      <ReservationFilterByPreviousTicket
      previousETicketsChecked={previousETicketsChecked}
      setPreviousETicketsChecked={setPreviousETicketsChecked}
      />
      <ReservationFilterByCancelledTicket
      cancelledETicketsChecked={cancelledETicketsChecked}
      setCancelledETicketsChecked={setCancelledETicketsChecked}
      />
    </fieldset>
  )
}

export default ReservationFilter
