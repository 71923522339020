import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'
import { CrossIcon } from '@/root/ui/shared/icons/CrossIcon'

interface FilterCellProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  value?: string
  setValue?: (value: string) => void
}

const FilterCell: FC<FilterCellProps> = ({ children, className, label, value, setValue }) => {
  const handleClearValue = () => {
    setValue?.('')
  }

  return (
    <div
      className={cn(
        'flex flex-col justify-start gap-2 text-xs font-bold text-primary-base lg:justify-center lg:items-center p-5 border-b lg:border-b-0 lg:border-e border-neutral-normal-light last:border-none',
        className
      )}
    >
      <div className="flex w-full justify-between">
        <span>{label}</span>
        {value && (
          <button onClick={handleClearValue} className="ml-2">
            <CrossIcon width={12} height={12} color="primary-base" />
          </button>
        )}
      </div>
      {children}
    </div>
  )
}

export default FilterCell
