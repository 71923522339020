import { ChangeEvent, FC } from 'react'
import cn from 'classnames'
import { LensIcon } from '@/root/ui/shared/icons/Lens'

interface Props {
  ticketNumber: string
  setTicketNumber: (ticketNumber: string) => void
}

const ReservationFilterById: FC<Props> = ({ ticketNumber, setTicketNumber }) => {
  const handleTicketNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (/^\d*$/.test(value)) {
      setTicketNumber(value)
    }
  }

  return (
    <div className="text-primary-base relative grid w-full text-xs font-bold lg:max-w-60">
      <div className="relative flex w-full items-center">
        <input
          id="reservation-number-filter"
          type="text"
          value={ticketNumber}
          onChange={handleTicketNumberChange}
          pattern="\d{1,5}"
          maxLength={5}
          onInput={(e) => {
            const input = e.target as HTMLInputElement
            input.value = input.value.replace(/\D/g, '')
          }}
          className={cn(
            'w-full border h-8 text-sm border-neutral-normal-light bg-white rounded focus:outline-none font-normal ps-2'
          )}
        />
        <span className="pointer-events-none absolute right-2">
          <LensIcon />
        </span>
      </div>
    </div>
  )
}

export default ReservationFilterById
