import { FC } from 'react'
import { Group as GroupProps } from '@/root/shared-types'
import { RowContainer } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/RowContainer/RowContainer'
import { TableCell } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/TableCell/TableCell'

import { KebabButton } from '@/root/ui/admaritime'
import { ProfilePopUp } from '@/root/components/admaritime/ProfilePopUp/ProfilePopUp'
import { KebabMenu } from '@/root/components/admaritime'
import { useCreateOrEditFamily, useGetTranslations, useModal } from '@/root/shared-hooks'

export const Group: FC<GroupProps> = (props) => {
  const { setModalBody } = useModal()
  const { getTranslation } = useGetTranslations()
  const { deleteFamily } = useCreateOrEditFamily()

  return (
    <RowContainer className="grid-cols-[25%_70%_5%]">
      <TableCell className="w-full whitespace-normal break-all">{props.legalName}</TableCell>
      <TableCell>{props.members.length}</TableCell>
      <div className="flex cursor-pointer justify-center">
        <KebabMenu>
          <KebabButton
            onClick={() =>
              setModalBody(
                <ProfilePopUp
                  type="editGroup"
                  members={props.members}
                  customers={props.customers || []}
                  group={props}
                />
              )
            }
          >
            {getTranslation('profileButtonEdit')}
          </KebabButton>
          <KebabButton onClick={() => deleteFamily(props.companyId)}>
            {getTranslation('profileButtonRemove')}
          </KebabButton>
        </KebabMenu>
      </div>
    </RowContainer>
  )
}
