export const admaritimeMessages = {
  headerLangSwitcher: {
    id: 'header.lang-switcher',
    defaultMessage: 'عربي',
    description: 'Locale switcher label in header',
  },
  headerRegisterOrLoginButton: {
    id: 'header.register-or-login-button',
    defaultMessage: 'Register/Login',
    description: 'Text for register/login button in header',
  },
  headerLogoutButtonLabel: {
    id: 'header.logout-button-label',
    defaultMessage: 'Log out',
    description: 'Text for logout button in header',
  },
  headerLoginButtonLabel: {
    id: 'header.login-button-label',
    defaultMessage: 'Welcome',
    description: 'Text for welcome auth user on the page',
  },
  ticketBannerTitle: {
    id: 'ticket.banner.title',
    defaultMessage: 'THANK YOU!',
    description: "Ticket's page banner title",
  },
  ticketBannerBody: {
    id: 'ticket.banner.body',
    defaultMessage:
      "Your payment has been successful and confirmation has been sent to your email. Click on 'Download' to access your ticket and booking.",
    description: "Ticket's page banner body",
  },
  ticketBannerErrorTitle: {
    id: 'ticket.error-banner.title',
    defaultMessage: 'WARNING',
    description: "Ticket's page error-banner title",
  },
  ticketBannerErrorBody: {
    id: 'ticket.error-banner.body',
    defaultMessage:
      'The server throws random errors with no specific codes. let’s pretend it’s aliens invasion, please reboot the Earth!',
    description: "Ticket's page error-banner body",
  },
  ticketActionButtonsPrint: {
    id: 'ticket.action-buttons.print',
    defaultMessage: 'Print out',
    description: 'Label for print action button',
  },
  ticketActionButtonsDownload: {
    id: 'ticket.action-buttons.download',
    defaultMessage: 'Download',
    description: 'Label for download action button',
  },
  ticketActionButtonsCancel: {
    id: 'ticket.action-buttons.cancel',
    defaultMessage: 'Cancel',
    description: 'Label for cancel action button',
  },
  ticketPrintedTicketNumber: {
    id: 'ticket.printed-ticket.number',
    defaultMessage: 'Ticket No:',
    description: 'Ticket number label',
  },
  ticketPrintedTicketVehicleNumberLabel: {
    id: 'ticket.printed-ticket.vehicle-number-label',
    defaultMessage: 'Vehicle reg. No',
    description: 'Vehicle number label',
  },
  cancelAndRemoveTicket: {
    id: 'reservation-refund.cancel-remove-title',
    defaultMessage: 'Cancel and remove E-ticket nr.',
    description: 'label for cancelAndRemoveTicket',
  },
  refundWarningText: {
    id: 'refund.warning-text',
    defaultMessage: 'You are about to cancel and remove following E-ticket:',
    description: 'Refund warning text',
  },
  keepTicket: {
    id: 'reservation-refund.keep-ticket',
    defaultMessage: 'I want to keep this ticket',
    description: 'label for keepTicket',
  },
  amountRefundTip: {
    id: 'reservation-refund.amount-refund-tip',
    defaultMessage:
      '* - will be refunded to you by the means on MPAY service, using same payment gateway that you chosen while purchasing the ticket(s).',
    description: 'label for amountRefundTip(maritime)',
  },
  amountPaid: {
    id: 'reservation-refund.amount-paid',
    defaultMessage: "Amount you've paid:",
    description: 'label for amountPaid',
  },
  percentRefund: {
    id: 'reservation-refund.percent-refund',
    defaultMessage: 'Percent of refund:',
    description: 'label for percentRefund',
  },
  amountRefund: {
    id: 'reservation-refund.amount-refund',
    defaultMessage: 'Amount to be refund:',
    description: 'label for amountRefund',
  },
  removeTicket: {
    id: 'reservation-refund.refund-remove-ticket',
    defaultMessage: 'Cancel and remove this E-ticket',
    description: 'label for removeTicket',
  },
  refundAgreement: {
    id: 'reservation-refund.refund-agreement',
    defaultMessage:
      "Yes, I want to cancel and remove this E-ticket and I'm agree to receive above mentioned amount as per Service Provider Agreement.",
    description: 'label for refundAgreement',
  },
  cancelRouteRefund: {
    id: 'reservation-refund.cancel-route',
    defaultMessage: 'Cancel this route',
    description: 'label for cancelRoute',
  },
  cancelled: {
    id: 'reservation-refund.sail-cancelled',
    defaultMessage: 'cancelled',
    description: 'label for cancelled',
  },
  ticketWasRefundTipMaritime: {
    id: 'reservation-refund.was-refund-tip-maritime',
    defaultMessage:
      'Amount of <b>{refundAmount}</b> will be returned to you by the means on MPAY service, using same payment gateway that you chosen while purchasing the ticket(s). If the refund was not received within 5-7 business days to your bank account please contact our Customer Support',
    description: 'label for ticketWasRefundTip',
  },
  mayClose: {
    id: 'reservation-refund.may-close',
    defaultMessage: 'You may close this window now.',
    description: 'label for mayClose',
  },
  ticketWasRefund: {
    id: 'reservation-refund.was-refund',
    defaultMessage: 'Your ticket nr. <b>{reservationId}</b> is successfully cancelled / deleted',
    description: 'label for ticketWasRefund',
  },
  userManualTitle: {
    id: 'user-manual.title',
    defaultMessage: 'User Manual',
    description: 'User Manual',
  },
  userManualTicketPurchase: {
    id: 'user-manual.ticket-purchase',
    defaultMessage: 'Tickets Purchase',
    description: 'Tickets Purchase',
  },
  userManualTicketPurchaseWhere: {
    id: 'user-manual.ticket-purchase.where',
    defaultMessage: 'Where',
    description: 'Where',
  },

  userManualTicketPurchaseWhereSelectRoute: {
    id: 'user-manual.ticket-purchase.where.select-route',
    defaultMessage: 'Select a preferred route from the drop-down list',
    description: 'Select a preferred route from the drop-down list',
  },
  userManualTicketPurchaseFerry: {
    id: 'user-manual.ticket-purchase.ferry',
    defaultMessage: 'Alternatively, choose the Ferry or Water Taxi routes below',
    description: 'Alternatively, choose the Ferry or Water Taxi routes below',
  },
  userManualTicketPurchaseWhen: {
    id: 'user-manual.ticket-purchase.when',
    defaultMessage: 'Where',
    description: 'Where',
  },

  userManualTicketPurchaseWhenSelectDate: {
    id: 'user-manual.ticket-purchase.when.select-date',
    defaultMessage: 'Select the departure date from the calendar',
    description: 'Select the departure date from the calendar',
  },
  userManualTicketPurchaseRoundTrip: {
    id: 'user-manual.ticket-purchase.round-trip',
    defaultMessage: 'Round-Trip',
    description: 'Round-Trip',
  },

  userManualTicketPurchaseRoundTripChooseDestination: {
    id: 'user-manual.ticket-purchase.round-trip.choose-destination',
    defaultMessage: 'For round-trip bookings, choose destination and select the ROUND-TRIP option by ticking the box',
    description: 'For round-trip bookings, choose destination and select the ROUND-TRIP option by ticking the box',
  },
  userManualTicketPurchaseGetTickets: {
    id: 'user-manual.ticket-purchase.get-tickets',
    defaultMessage: 'Get Tickets',
    description: 'Get Tickets',
  },
  userManualTicketPurchaseGetTicketsClick: {
    id: 'user-manual.ticket-purchase.get-tickets.click',
    defaultMessage: 'Click GET TICKETS button',
    description: 'Click GET TICKETS button',
  },
  userManualDepartureDate: {
    id: 'user-manual.departure-date',
    defaultMessage: 'Tickets Purchase - Departure Date / Time',
    description: 'Tickets Purchase - Departure Date / Time',
  },
  userManualDepartureDateSelectDate: {
    id: 'user-manual.departure-date.select-date',
    defaultMessage: 'Select date and time',
    description: 'Select date and time',
  },

  userManualDepartureDateSelectDateNote: {
    id: 'user-manual.departure-date.select-date.note',
    defaultMessage: 'Select suitable time Note: always check available capacity',
    description: 'Select suitable time Note: always check available capacity',
  },
  userManualDepartureDateChangeDate: {
    id: 'user-manual.departure-date.changed-date',
    defaultMessage: 'Change date',
    description: 'Change date',
  },

  userManualDepartureDateChangeDateNoSeats: {
    id: 'user-manual.departure-date.changed-date.no-seats',
    defaultMessage: 'Change date if no seats available at the chosen time slot',
    description: 'Change date if no seats available at the chosen time slot',
  },

  userManualDepartureDateReturnRoute: {
    id: 'user-manual.departure-date.return-route',
    defaultMessage: 'Add return route (optional)',
    description: 'Add return route (optional)',
  },

  userManualDepartureDateReturnRouteNote: {
    id: 'user-manual.departure-date.return-route.note',
    defaultMessage:
      'Note: if you did not select the round-trip ticket at the initial stage, and a return ticket is needed, you can click on ADD RETURN ROUTE, and choose the date and time of your trip',
    description:
      'Note: if you did not select the round-trip ticket at the initial stage, and a return ticket is needed, you can click on ADD RETURN ROUTE, and choose the date and time of your trip',
  },
  userManualDepartureDateSelectTicket: {
    id: 'user-manual.departure-date.select-ticket',
    defaultMessage: 'Select ticket',
    description: 'Select ticket',
  },
  userManualDepartureDateSelectTicketClick: {
    id: 'user-manual.departure-date.select-ticket.click',
    defaultMessage: 'To continue click on SELECT TICKET',
    description: 'To continue click on SELECT TICKET',
  },
  userManualTicketSelection: {
    id: 'user-manual.ticket-selection',
    defaultMessage: 'Tickets Purchase - Ticket(s) Selection',
    description: 'Tickets Purchase - Ticket(s) Selection',
  },
  userManualTicketSelectionFullFreePassengers: {
    id: 'user-manual.ticket-selection.full/free-passengers',
    defaultMessage: 'Full/Free of charge passenger tickets',
    description: 'Full/Free of charge passenger tickets',
  },

  userManualTicketSelectionFullFreePassengersSelectType: {
    id: 'user-manual.ticket-selection.full/free-passengers.select-type',
    defaultMessage: 'To add passengers, select preferred ticket type from ',
    description: 'To add passengers, select preferred ticket type from ',
  },
  userManualTicketSelectionFillInData: {
    id: 'user-manual.ticket-selection.fill-in-data',
    defaultMessage: 'Fill in passenger data if requested and click on the SAVE button',
    description: 'Fill in passenger data if requested and click on the SAVE button',
  },
  userManualTicketVehicleTickets: {
    id: 'user-manual.ticket-selection.vehicle-tickets',
    defaultMessage: 'Vehicles tickets',
    description: 'Vehicles tickets',
  },
  userManualTicketVehicleTicketsChooseType: {
    id: 'user-manual.ticket-selection.vehicle-tickets.choose-type',
    defaultMessage: 'Choose vehicle type and add vehicle license plate number',
    description: 'Choose vehicle type and add vehicle license plate number',
  },
  userManualTicketCheckYourCart: {
    id: 'user-manual.ticket-selection.check-your-cart',
    defaultMessage: 'Check your cart',
    description: 'Check your cart',
  },

  userManualTicketCheckClickContinue: {
    id: 'user-manual.ticket-selection.click-continue',
    defaultMessage: 'Click Continue to CHECK-OUT button',
    description: 'Click Continue to CHECK-OUT button',
  },

  userManualCheckOut: {
    id: 'user-manual.check-out',
    defaultMessage: 'Tickets Purchase - Check-out',
    description: 'Tickets Purchase - Check-out',
  },

  userManualCheckOutFillInfo: {
    id: 'user-manual.check-out.fill-info',
    defaultMessage: 'Fill customer information',
    description: 'Fill customer information',
  },

  userManualCheckOutTermsAndConditions: {
    id: 'user-manual.check-out.terms-and-conditions',
    defaultMessage: 'Agree to the terms and conditions',
    description: 'Agree to the terms and conditions',
  },

  userManualCheckOutPayment: {
    id: 'user-manual.check-out.payment',
    defaultMessage: 'Payment',
    description: 'Payment',
  },

  userManualCheckOutPaymentSelectOption: {
    id: 'user-manual.check-out.payment.select-option',
    defaultMessage: 'Select the payment option and proceed with payment',
    description: 'Select the payment option and proceed with payment',
  },

  userManualPurchaseConfirmation: {
    id: 'user-manual.purchase-confirmation',
    defaultMessage: 'Tickets Purchase - Purchase Confirmation',
    description: 'Tickets Purchase - Purchase Confirmation',
  },

  userManualPurchaseConfirmationDownloadTicket: {
    id: 'user-manual.purchase-confirmation.download-ticket',
    defaultMessage: 'Download the ticket',
    description: 'Download the ticket',
  },

  userManualPurchaseConfirmationDownloadTicketChooseOption: {
    id: 'user-manual.purchase-confirmation.download-ticket.choose-option',
    defaultMessage: 'Choose DOWNLOAD option',
    description: 'Choose DOWNLOAD option',
  },

  userManualPurchaseConfirmationCancelTicket: {
    id: 'user-manual.purchase-confirmation.cancel-ticket',
    defaultMessage: 'Cancel the ticket',
    description: 'Cancel the ticket',
  },

  userManualPurchaseConfirmationCancelTicketClick: {
    id: 'user-manual.purchase-confirmation.cancel-ticket.click',
    defaultMessage: 'To cancel booking, click CANCEL button',
    description: 'To cancel booking, click CANCEL button',
  },

  userManualTicketCancellation: {
    id: 'user-manual.ticket-cancellation',
    defaultMessage: 'Tickets Purchase - Ticket Cancellation',
    description: 'Tickets Purchase - Ticket Cancellation',
  },

  userManualTicketCancellationOneWay: {
    id: 'user-manual.ticket-cancellation.one-way',
    defaultMessage: 'One-way',
    description: 'One-way',
  },

  userManualTicketCancellationRoundTrip: {
    id: 'user-manual.ticket-cancellation.round-trip',
    defaultMessage: 'Round-trip',
    description: 'Round-trip',
  },

  userManualSignUpEmailMobile: {
    id: 'user-manual.sign-up-email-mobile',
    defaultMessage: 'SIGN UP \nEmail/Mobile',
    description: 'SIGN UP Email/Mobile',
  },

  userManualSignUpEmailMobileEnterInfo: {
    id: 'user-manual.sign-up-email-mobile.enter-info',
    defaultMessage: 'Enter required information',
    description: 'Enter required information',
  },

  userManualSignUpEmailMobileAccountVerification: {
    id: 'user-manual.sign-up-email-mobile.account-verification',
    defaultMessage: 'Account verification',
    description: 'Account verification',
  },

  userManualSignInEmailMobile: {
    id: 'user-manual.sign-in-email-mobile',
    defaultMessage: 'SIGN IN \n Email/Mobile',
    description: 'SIGN IN Email/Mobile',
  },

  userManualSignUpUaePass: {
    id: 'user-manual.sign-up-uae-pass',
    defaultMessage: 'SIGN UP / SIGN IN \n UAE PASS',
    description: 'SIGN UP / SIGN IN UAE PASS',
  },

  userManualSignUpUaePassContinue: {
    id: 'user-manual.sign-up-uae-pass.continue',
    defaultMessage: 'Continue with UAE PASS',
    description: 'Continue with UAE PASS',
  },

  userManualSignUpUaePassAccessRequest: {
    id: 'user-manual.sign-up-uae-pass.access-request',
    defaultMessage: 'Access Request',
    description: 'Access Request',
  },

  userManualSignUpFacebook: {
    id: 'user-manual.sign-up-facebook',
    defaultMessage: 'SIGN UP / SIGN IN \nFACEBOOK',
    description: 'SIGN UP / SIGN IN FACEBOOK',
  },

  userManualSignUpFacebookClick: {
    id: 'user-manual.sign-up-facebook.click',
    defaultMessage: 'Click on FACEBOOK button',
    description: 'Click on FACEBOOK button',
  },

  userManualSignUpFacebookLogIn: {
    id: 'user-manual.sign-up-facebook.log-in',
    defaultMessage: 'Log in to FACEBOOK',
    description: 'Log in to FACEBOOK',
  },

  userManualSignUpFacebookLogInClickContinue: {
    id: 'user-manual.sign-up-facebook.log-in.click-continue',
    defaultMessage: 'Click on “Continue as User”',
    description: 'Click on “Continue as User”',
  },
  userManualSignUpGoogle: {
    id: 'user-manual.sign-up-google',
    defaultMessage: 'SIGN UP / SIGN IN \nGOOGLE',
    description: 'SIGN UP / SIGN IN GOOGLE',
  },

  userManualSignUpGoogleClick: {
    id: 'user-manual.sign-up-google.click',
    defaultMessage: 'Click on GOOGLE button',
    description: 'Click on GOOGLE button',
  },

  userManualSignUpGoogleLogIn: {
    id: 'user-manual.sign-up-google.log-in',
    defaultMessage: 'Log in to GOOGLE',
    description: 'Log in to GOOGLE',
  },

  userManualProfile: {
    id: 'user-manual.profile',
    defaultMessage: 'PROFILE',
    description: 'PROFILE',
  },
  userManualProfileClick: {
    id: 'user-manual.profile.click',
    defaultMessage: 'Click on PROFILE',
    description: 'Click on PROFILE',
  },

  userManualProfileEnterCustomersInfo: {
    id: 'user-manual.profile.enter-customers-info',
    defaultMessage: 'Enter customer’s information',
    description: 'Enter customer’s information',
  },

  userManualRegistrationLogin: {
    id: 'user-manual.registration-login',
    defaultMessage: 'User Registration / Login',
    description: 'User Registration / Login',
  },

  newsPageTitle: {
    id: 'news-page.title',
    defaultMessage: 'Notifications',
    description: 'Notifications',
  },

  tariffsPageTitle: {
    id: 'tariffs-page.title',
    defaultMessage: 'Tariffs',
    description: 'Tariffs',
  },

  profilePageTitle: {
    id: 'profile-page.title',
    defaultMessage: 'Profile',
    description: 'Profile',
  },

  schedulePageTableDeparture: {
    id: 'schedule-page.table.departure',
    defaultMessage: 'Departure',
    description: 'Departure',
  },

  schedulePageTableFirstDeparture: {
    id: 'schedule-page.table.first-departure',
    defaultMessage: 'First Departure',
    description: 'First Departure',
  },
  schedulePageLastDeparture: {
    id: 'schedule-page.table.last-departure',
    defaultMessage: 'Last Departure',
    description: 'Last Departure',
  },

  schedulePageTableDepartureTime: {
    id: 'schedule-page.table.departure-time',
    defaultMessage: 'Departure Time',
    description: 'Departure Time',
  },
  schedulePageTableArrivalTime: {
    id: 'schedule-page.table.arrival-time',
    defaultMessage: 'Arrival Time',
    description: 'Arrival Time',
  },

  schedulePageTableDays: {
    id: 'schedule-page.table.days',
    defaultMessage: 'Days',
    description: 'Days',
  },

  schedulePageTitle: {
    id: 'schedule-page.title',
    defaultMessage: 'Ferries/Water Taxi Schedule',
    description: 'Ferries/Water Taxi Schedule',
  },

  sitemapPageTitle: {
    id: 'sitemap-page.title',
    defaultMessage: 'Sitemap',
    description: 'Sitemap',
  },

  vesselCardLabelLength: {
    id: 'vessel-card.label.length',
    defaultMessage: 'Length',
    description: 'Length',
  },

  vesselCardLabelTonnage: {
    id: 'vessel-card.label.tonnage',
    defaultMessage: 'Tonnage',
    description: 'Tonnage',
  },

  vesselCardLabelMaxSpeed: {
    id: 'vessel-card.label.maxSpeed',
    defaultMessage: 'Max speed',
    description: 'Max speed',
  },

  vesselCardLabelCrew: {
    id: 'vessel-card.label.crew',
    defaultMessage: 'Crew',
    description: 'Crew',
  },

  vesselCardLabelPassengers: {
    id: 'vessel-card.label.passengers',
    defaultMessage: 'Passengers',
    description: 'Passengers',
  },

  vesselCardLabelVehicles: {
    id: 'vessel-card.label.vehicles',
    defaultMessage: 'Vehicles',
    description: 'Vehicles',
  },

  vesselCardLabelBuilt: {
    id: 'vessel-card.label.built',
    defaultMessage: 'Year of built',
    description: 'Year of built',
  },
  searchBarWherePlaceholder: {
    id: 'search-bar.where-placeholder',
    defaultMessage: 'Choose route',
    description: 'Choose route',
  },
  searchBarWhenPlaceholder: {
    id: 'search-bar.when-placeholder',
    defaultMessage: 'Pick a date from calendar',
    description: 'Pick a date from calendar',
  },

  footerCopyright: {
    id: 'footer.copyright',
    defaultMessage: 'Abu Dhabi Maritime | Powered by Maqta Gateway',
    description: 'Abu Dhabi Maritime | Powered by Maqta Gateway',
  },

  continueButton: {
    id: 'continue.button',
    defaultMessage: 'Continue',
    description: 'Continue',
  },

  chooseFerryLine: {
    id: 'choose-ferry-line.title',
    defaultMessage: 'Choose a Ferry Line',
    description: 'Choose a Ferry Line',
  },

  chooseWaterTaxi: {
    id: 'choose-water-taxi.title',
    defaultMessage: 'Choose a Water Taxi',
    description: 'Choose a Water Taxi',
  },

  semiLoopRideTitle: {
    id: 'semi-loop-ride.title',
    defaultMessage: 'Semi-loop ride',
    description: 'Semi-loop ride taxi',
  },

  fullLoopRideTitle: {
    id: 'full-loop-ride.title',
    defaultMessage: 'Full loop ride',
    description: 'Full loop ride Taxi',
  },

  fullDayRoundTitle: {
    id: 'full-day-round.title',
    defaultMessage: 'Full day round',
    description: 'Full day round',
  },
  fullLoopRideLabel: {
    id: 'full-loop-ride.label',
    defaultMessage: 'Trip starts from {port} and finishes at {port} completing a full loop',
    description: 'Text for Full loop ride Taxi',
  },
  bookTicketBadgeTitle: {
    id: 'book-ticket.badge.title',
    defaultMessage: 'Book Ticket',
    description: 'Book Ticket',
  },
  fullDayRoundLabel: {
    id: 'full-day-round.label',
    defaultMessage: 'Jump onboard at any stop you like and finish your journey at the same stop completing a full loop',
    description: 'Full day round label',
  },
  userNotExist: {
    id: 'auth.signin-failed-user-not-exist',
    defaultMessage: 'User does not exist',
    description: 'Error on Forgot password tab',
  },
  userNotValid: {
    id: 'auth.signin-failed-user-not-valid',
    defaultMessage: 'Email or Mobile number or Password is Incorrect. Please try again',
    description: 'Error on Forgot password tab',
  },
  forgotPassHeaderTitle: {
    id: 'login.sign-forgot-pass-header-title',
    defaultMessage: 'Forgot password',
    description: 'Forgot password',
  },
  recoveryMailPlaceholder: {
    id: 'recovery-fields.mail-placeholder',
    defaultMessage: 'Enter your registered email/mobile number',
    description: 'Enter your registered email/mobile number',
  },
  requiredError: {
    id: 'common-fields.required-error',
    defaultMessage: 'Required',
    description: 'Required',
  },
  recoverySendButton: {
    id: 'recovery.send-button',
    defaultMessage: 'Send',
    description: 'Send',
  },
  invalidFormatText: {
    id: 'invalid-format-text',
    defaultMessage: 'Invalid format',
    description: 'Invalid format',
  },
  ticketsLabel: {
    id: 'my-reservations.tickets-label',
    defaultMessage: 'Tickets',
    description: 'Tickets',
  },
  passengersData: {
    id: 'passengers-data.label',
    defaultMessage: 'Passengers and Groups',
    description: 'Passengers and Groups',
  },
  customerCredentials: {
    id: 'customer.credentials-label',
    defaultMessage: "Customer's Information",
    description: "Customer's Information",
  },
  customerSectionText: {
    id: 'customer.person-section-text',
    defaultMessage:
      'Private customer (this information is used for invoicing and contacting to you, like sending you tickets)',
    description: 'Warning text',
  },
  cookiesCollect: {
    id: 'cookie-banner-description',
    defaultMessage: '{url} website uses cookies. By continuing to use this website you agree to our to our',
    description: 'Collecting Cookies label',
  },
  privacyPolicy: {
    id: 'privacy-policy',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy Policy',
  },
  cookiesAgreement: {
    id: 'agree-privacy-policy',
    defaultMessage: 'Agree',
    description: 'Agreement button',
  },
  customerInformationEdit: {
    id: 'customer-information-edit',
    defaultMessage: 'Edit',
    description: 'Edit button',
  },
  customerInformationSave: {
    id: 'customer-information-save',
    defaultMessage: 'Save',
    description: 'Save button',
  },
  customerInformationCancel: {
    id: 'customer-information-cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  },
  customerInformationFistName: {
    id: 'label.customer-information-firstname',
    defaultMessage: 'First Name',
    description: 'First Name label',
  },
  customerInformationLastName: {
    id: 'label.customer-information-lastname',
    defaultMessage: 'Last Name',
    description: 'Last Name label',
  },
  customerInformationPhone: {
    id: 'label.customer-information-phone',
    defaultMessage: 'Phone number',
    description: 'Phone number label',
  },
  customerInformationEmail: {
    id: 'label.customer-information-email',
    defaultMessage: 'E-mail',
    description: 'E-mail label',
  },

  searchBarWhereLabel: {
    id: 'search-bar.where-label',
    defaultMessage: 'Where',
    description: 'Where',
  },
  searchBarWhenLabel: {
    id: 'search-bar.when-label',
    defaultMessage: 'When',
    description: 'When',
  },

  searchBarRoundTrip: {
    id: 'search-bar.round-trip',
    defaultMessage: 'Round-Trip',
    description: 'Round-Trip',
  },

  searchBarGetTickets: {
    id: 'search-bar.get-tickets',
    defaultMessage: 'Get Tickets',
    description: 'Get Tickets',
  },
  authMethodEmailMobile: {
    id: 'auth.method.email.mobile',
    defaultMessage: 'Email/Mobile',
    description: 'authorisation by email/mobile',
  },
  authMethodFacebook: {
    id: 'auth.method.facebook',
    defaultMessage: 'Facebook',
    description: 'authorisation by Facebook',
  },
  authMethodGoogle: {
    id: 'auth.method.google',
    defaultMessage: 'Google',
    description: 'authorisation by Google',
  },
  signInEnter: {
    id: 'sign-in.enter',
    defaultMessage: 'Enter',
    description: 'Enter',
  },
  authSigninWithEmail: {
    id: 'auth.signin-with-email',
    defaultMessage: 'SIGN IN WITH EMAIL/MOBILE',
    description: 'SIGN IN WITH EMAIL/MOBILE',
  },
  authSigninWithFacebook: {
    id: 'auth.signin-with-facebook',
    defaultMessage: 'SIGN IN WITH FACEBOOK',
    description: 'SIGN IN WITH FACEBOOK',
  },
  authSigninWithGoogle: {
    id: 'auth.signin-with-google',
    defaultMessage: 'SIGN IN WITH GOOGLE',
    description: 'SIGN IN WITH GOOGLE',
  },
  authSigninWithUae: {
    id: 'auth.signin-with-uae',
    defaultMessage: 'SIGN IN WITH UAE PASS',
    description: 'SIGN IN WITH UAE PASS',
  },
  authSignupWithEmail: {
    id: 'auth.signup-with-email',
    defaultMessage: 'SIGN UP WITH EMAIL/MOBILE',
    description: 'SIGN UP WITH EMAIL/MOBILE',
  },
  authSignupWithFacebook: {
    id: 'auth.signup-with-facebook',
    defaultMessage: 'SIGN UP WITH FACEBOOK',
    description: 'SIGN UP WITH FACEBOOK',
  },
  authSignupWithGoogle: {
    id: 'auth.signup-with-google',
    defaultMessage: 'SIGN UP WITH GOOGLE',
    description: 'SIGN UP WITH GOOGLE',
  },
  authSignupWithUae: {
    id: 'auth.signup-with-uae',
    defaultMessage: 'SIGN UP WITH UAE PASS',
    description: 'SIGN UP WITH UAE PASS',
  },
  authOrLabel: {
    id: 'auth-or-label',
    defaultMessage: 'or',
    description: 'or',
  },
  signInIntro: {
    id: 'sign-in.intro',
    defaultMessage: 'Please choose the authentication method you prefer',
    description: 'authentication method selection',
  },
  signUAEPassTitle: {
    id: 'sign.signUAEPass-title',
    defaultMessage: 'Continue with UAE PASS',
    description: 'button - Continue with UAE PASS',
  },
  invalidPasswordText: {
    id: 'invalid-password-text',
    defaultMessage:
      'Password must contain one digit from 1 to 9, one uppercase letter, one special character, no space, and it must be at least 8 characters',
    description: 'password validation',
  },
  signFieldPasswordPlaceholder: {
    id: 'sign-fields.password-placeholder',
    defaultMessage: 'type your password',
    description: 'type your password',
  },
  signFieldMailPlaceholder: {
    id: 'sign-fields.mail-or-phone-number-placeholder',
    defaultMessage: 'Email/Mobile +97112345678',
    description: 'Email/Mobile +97112345678',
  },
  confirmationTicketType: {
    id: 'confirmation.ticket-type',
    defaultMessage: 'Ticket type',
    description: 'Ticket type',
  },
  confirmationQty: {
    id: 'confirmation.qty',
    defaultMessage: 'Qty',
    description: 'Qty',
  },
  confirmationPrice: {
    id: 'confirmation.price',
    defaultMessage: 'Price',
    description: 'Price',
  },
  confirmationVAT: {
    id: 'confirmation.VAT',
    defaultMessage: 'VAT (5%)',
    description: 'VAT',
  },
  confirmationSubtotal: {
    id: 'confirmation.subtotal',
    defaultMessage: 'Subtotal',
    description: 'Subtotal',
  },
  confirmationTotal: {
    id: 'confirmation.total',
    defaultMessage: 'TOTAL TO PAY:',
    description: 'TOTAL TO PAY:',
  },
  confirmationDescription: {
    id: 'confirmation.description',
    defaultMessage: 'Description',
    description: 'Description',
  },

  confirmationOnlineChargesLabel: {
    id: 'confirmation.online-charges-label',
    defaultMessage: 'Online Charges',
    description: 'Online Charges',
  },

  confirmationOnlineChargesModalDescription: {
    id: 'confirmation.online-charges-modal-description',
    defaultMessage:
      'Dear Customer, please note that a processing fee will apply as 0.76% of the total amount plus AED 1 and VAT for each transaction',
    description:
      'Dear Customer, please note that a processing fee will apply as 0.76% of the total amount plus AED 1 and VAT for each transaction',
  },

  confirmationOnlineChargesModalTitle: {
    id: 'confirmation.online-charges-modal-title',
    defaultMessage: 'What is Online Charges',
    description: 'What is Online Charges',
  },

  confirmationTermsAndConditionsTermsQuestions: {
    id: 'confirmation.terms-and-conditions.terms-question',
    defaultMessage: 'I hereby agree to the below Terms and Conditions',
    description: 'I hereby agree to the below Terms and Conditions',
  },

  confirmationVehicleInformationTitle: {
    id: 'confirmation.vehicle-information.title',
    defaultMessage: 'Vehicle Information',
    description: 'Vehicle Information',
  },

  confirmationCarLength: {
    id: 'confirmation.customer-info.car-length',
    defaultMessage: 'Length',
    description: 'Length',
  },

  confirmationCarWeight: {
    id: 'confirmation.customer-info.car-weight',
    defaultMessage: 'Weight',
    description: 'Weight',
  },

  confirmationCarWidth: {
    id: 'confirmation.customer-info.car-width',
    defaultMessage: 'Width',
    description: 'Width',
  },

  confirmationCarHeight: {
    id: 'confirmation.customer-info.car-height',
    defaultMessage: 'Height',
    description: 'Height',
  },
  confirmationCarRegNr: {
    id: 'confirmation.customer-info.car-reg-nr',
    defaultMessage: 'Vehicle No.',
    description: 'Vehicle No.',
  },

  confirmationCarHeightLarge: {
    id: 'confirmation.customer-info.car-height-large',
    defaultMessage: 'More than 4m',
    description: 'More than 4m',
  },
  confirmationCarHeightSmall: {
    id: 'confirmation.customer-info.car-height-small',
    defaultMessage: 'Less than 4m',
    description: 'Less than 4m',
  },

  confirmationCarWidthOver2: {
    id: 'confirmation.customer-info.car-width-over-2',
    defaultMessage: 'More than 2m',
    description: 'More than 2m',
  },
  confirmationCarWidthSmall: {
    id: 'confirmation.customer-info.car-width-small',
    defaultMessage: 'Less than 2m',
    description: 'Less than 2m',
  },
  confirmationCarWidthMedium: {
    id: 'confirmation.customer-info.car-width-medium',
    defaultMessage: 'From 2m to 2.6m',
    description: 'From 2m to 2.6m',
  },
  confirmationCarWidthLarge: {
    id: 'confirmation.customer-info.car-width-large',
    defaultMessage: 'More than 2.6m',
    description: 'More than 2.6m',
  },

  confirmationContinueToLabel: {
    id: 'confirmation.continue-to.label',
    defaultMessage: 'Continue to',
    description: 'Continue to',
  },
  confirmationSubmitButton: {
    id: 'confirmation.submit-button',
    defaultMessage: 'Submit',
    description: 'Submit',
  },

  confirmationPaymentWarning: {
    id: 'confirmation.payment-warning',
    defaultMessage: 'You must agree to terms of services to proceed',
    description: 'You must agree to terms of services to proceed',
  },
  confirmationPayWithCard: {
    id: 'confirmation.pay-with-card',
    defaultMessage: 'Pay with card',
    description: 'Pay with card',
  },
  confirmationApplePay: {
    id: 'confirmation.apple-pay',
    defaultMessage: 'Pay',
    description: 'Pay',
  },

  confirmationContactFormCustomerInformation: {
    id: 'confirmation.contact-form.customer-information',
    defaultMessage: 'Customer information',
    description: 'Customer information',
  },

  confirmationContactFormFirstName: {
    id: 'confirmation.contact-form.first-name',
    defaultMessage: 'Name',
    description: 'Name',
  },

  confirmationContactFormLastName: {
    id: 'confirmation.contact-form.last-name',
    defaultMessage: 'Surname',
    description: 'Surname',
  },

  confirmationContactFormEmail: {
    id: 'confirmation.contact-form.e-mail',
    defaultMessage: 'E-mail',
    description: 'E-mail',
  },

  confirmationContactFormPhoneNumber: {
    id: 'confirmation.contact-form.phoneNumber',
    defaultMessage: 'Mob. phone No.',
    description: 'Mob. phone No.',
  },

  confirmationContactFormPhoneRequiredError: {
    id: 'confirmation.contact-form.required-error',
    defaultMessage: 'Required',
    description: 'Required',
  },

  confirmationContactFormInvalidFormatError: {
    id: 'confirmation.contact-form.invalid-format-error',
    defaultMessage: 'Invalid format',
    description: 'Invalid format',
  },

  confirmationContactFormInvalidEmailError: {
    id: 'confirmation.contact-form.invalid-email-format-error',
    defaultMessage: 'Invalid email address',
    description: 'Invalid email address',
  },

  confirmationContactFormFirstNamePlaceHolder: {
    id: 'confirmation.contact-form.first-name-placeholder',
    defaultMessage: 'Type your first name here',
    description: 'Type your first name here',
  },

  confirmationContactFormLastNamePlaceHolder: {
    id: 'confirmation.contact-form.last-name-placeholder',
    defaultMessage: 'Type your last name here',
    description: 'Type your last name here',
  },

  confirmationContactFormEmailPlaceHolder: {
    id: 'confirmation.contact-form.email-placeholder',
    defaultMessage: 'Type your e-mail here',
    description: 'Type your e-mail here',
  },

  confirmationContactFormCodePlaceHolder: {
    id: 'confirmation.contact-form.code-placeholder',
    defaultMessage: '123',
    description: 'Code PlaceHolder',
  },

  confirmationContactFormPhonePlaceHolder: {
    id: 'confirmation.contact-form.phone-placeholder',
    defaultMessage: '123456789',
    description: 'Phone PlaceHolder',
  },

  bookingProcessError: {
    id: 'bookingProcessError',
    defaultMessage: 'An error occurred during the booking process, please try again later',
    description: 'An error occurred during the booking process, please try again later',
  },

  confirmationBookingPaymentProcessError: {
    id: 'confirmation.bookingPaymentProcessError',
    defaultMessage: 'Due to some reason payment was not successful.',
    description: 'Due to some reason payment was not successful.',
  },

  bookingProcessErrorTitle: {
    id: 'bookingProcessError.title',
    defaultMessage: 'Warning!',
    description: 'Warning!',
  },

  signUpTitle: {
    id: 'login.sign-up-title',
    defaultMessage: 'Sign up',
    description: 'Sign up',
  },
  signInTitle: {
    id: 'login.sign-in-title',
    defaultMessage: 'Sign in',
    description: 'Sign in',
  },
  signForgotPass: {
    id: 'login.sign-forgot-pass',
    defaultMessage: 'Forgot password?',
    description: 'Forgot password?',
  },
  loginFailed: {
    id: 'login.sign-failed',
    defaultMessage: 'Incorrect login or password',
    description: 'Incorrect login or password',
  },
  destinationStep: {
    id: 'view-progress.destination-step',
    defaultMessage: 'Select date and time',
    description: 'Select date and time',
  },
  selectTicketStep: {
    id: 'view-progress.select-ticket-step',
    defaultMessage: 'Select Ticket(s)',
    description: 'Select Ticket(s)',
  },
  selectConfirmStep: {
    id: 'view-progress.select-confirm-step',
    defaultMessage: 'Confirm & Pay',
    description: 'Confirm & Pay',
  },
  changeDateLabel: {
    id: 'main-view.changeDate-label',
    defaultMessage: 'Change date',
    description: 'Change date',
  },
  changeDirectionLabel: {
    id: 'main-view.changeDirection-label',
    defaultMessage: 'Change direction',
    description: 'Change direction',
  },
  durationOnlyWithHours: {
    id: 'duration-only-with-hours',
    defaultMessage: 'Travel time {hours} hour(s)',
    description: 'Travel time {hours} hour(s)',
  },
  durationOnlyWithMinutes: {
    id: 'duration-only-with-minutes',
    defaultMessage: 'Travel time {minutes} minute(s)',
    description: 'Travel time {minutes} minute(s)',
  },
  durationWithHoursAndMinutes: {
    id: 'duration-with-hours-and-minutes',
    defaultMessage: 'Travel time {hours} hour(s) \n {minutes} minute(s)',
    description: 'Travel time {hours} hour(s) {minutes} minute(s)',
  },
  addReturnRouteLabel: {
    id: 'main-view.add-returnRoute-label',
    defaultMessage: 'Add return route',
    description: 'Add return route',
  },

  paymentPendingHeading: {
    id: 'payment.pending.heading',
    defaultMessage: 'Stay tuned!',
    description: 'Stay tuned!',
  },

  paymentPendingReloadButton: {
    id: 'payment.pending.reload.button',
    defaultMessage: 'Refresh',
    description: 'Refresh',
  },
  paymentPendingText: {
    id: 'payment.pending.text',
    defaultMessage:
      'Thank you for your purchase! Currently your payment is still processing. It may take some time for you to get the confirmation of payment. When payment is confirmed you will receive your tickets. Please stay on this page or leave it and wait for confirmation email.',
    description: 'Payment pending text',
  },

  ticketReservationNotFoundError: {
    id: 'ticket.reservation.not.found.error',
    defaultMessage: 'Reservation Not Found! Solution is to start over reservation process.',
    description: 'Reservation Not Found! Solution is to start over reservation process.',
  },
  ticketReservationNotFoundButton: {
    id: 'ticket.reservation.not.found.button',
    defaultMessage: 'Back to main page',
    description: 'Back to main page',
  },
  noMoreSailsTitle: {
    id: 'no-more-sails-title',
    defaultMessage: 'No more sails available on the selected date!',
    description: 'No more sails available on the selected date!',
  },
  noMoreSailsSubtitle: {
    id: 'no-more-sails-subtitle',
    defaultMessage: 'Choose a new available sail clicking on a specific date in the calendar',
    description: 'Choose a new available sail clicking on a specific date in the calendar',
  },
  availableCapacity: {
    id: 'available-capacity',
    defaultMessage: 'Available capacity',
    description: 'Available capacity',
  },
  clueAdmVehicleAndPassenger: {
    id: 'clue-adm-vehicle-and-passenger',
    defaultMessage:
      'Kindly note that the vehicle ticket does not provide travel for the driver or passengers. Please purchase individual tickets for both the driver and each passenger accompanying the vehicle.',
    description: 'Note',
  },
  outOfStockPart1: {
    id: 'choose-sail.out-of-stock-part1',
    defaultMessage: 'Pre-bookable capacity is sold out.',
    description: 'Pre-bookable capacity is sold out.',
  },
  profileAddNewGroupPopUpTitle: {
    id: 'profile.add-new-group-popup.title',
    defaultMessage: 'Create new group of passengers',
    description: 'Create new group of passengers',
  },

  profileAddNewGroupPopUpLabel: {
    id: 'profile.add-new-group-popup.label',
    defaultMessage: 'Name of group',
    description: 'Name of group',
  },
  profileAddNewGroupPopUpAddPassenger: {
    id: 'profile.add-new-group-popup.add-passenger',
    defaultMessage: 'You can add passengers to group',
    description: 'You can add passengers to group',
  },
  profileAddNewPassengerPopUpAddTitle: {
    id: 'profile.add-new-passenger-popup.title',
    defaultMessage: 'Add / Edit passenger data',
    description: 'Add / Edit passenger data',
  },
  profileAddToGroupPopUpTitle: {
    id: 'profile.add-to-group-popup.title',
    defaultMessage: 'Add to groups',
    description: 'Add to groups',
  },
  profileEditGroupPopUpTitle: {
    id: 'profile.edit-new-group-title',
    defaultMessage: 'Edit group of passengers',
    description: 'Edit group of passengers',
  },
  profileAddToGroupPopUpBelongsToGroups: {
    id: 'profile.add-to-group-popup.belongs-to-groups',
    defaultMessage: 'Belongs to group(s)',
    description: 'Belongs to group(s)',
  },
  profileAddToGroupPopUpButtonText: {
    id: 'profile.add-to-group-popup.button-text',
    defaultMessage: 'Done',
    description: 'Done',
  },
  profileBirthDatePlaceholder: {
    id: 'profile.add-to-group-popup.birthdate-placeholder',
    defaultMessage: 'DD-MM-YYYY',
    description: 'Text that appears as a placeholder for input - date of birth',
  },
  profileBirthDateInvalidFormatText: {
    id: 'profile.add-to-group-popup.invalid-format',
    defaultMessage: 'Invalid date. It must match the DD-MM-YYYY format and be correct',
    description: 'Text that appears when incorrect date format is put in input - date of birth',
  },
  profileBirthDateInvalidDateText: {
    id: 'profile.add-to-group-popup.invalid-date-format',
    defaultMessage: 'Invalid date. Please, ensure it is a valid day, month and year within the allowed range',
    description: 'Text that appears when incorrect date is put in input - date of birth',
  },
  profileAddToGroupPopUpHintText: {
    id: 'profile.add-to-group-popup.hint-text',
    defaultMessage: 'Select available groups',
    description: 'Select available groups',
  },
  selectTicketLabel: {
    id: 'select-ticket-label',
    defaultMessage: 'Select Ticket',
    description: 'Select Ticket',
  },
  continueLabel: {
    id: 'continue-label',
    defaultMessage: 'continue to',
    description: 'continue to',
  },
  goBackChangeRoute: {
    id: 'go-back-change-route',
    defaultMessage: 'Go back to change route',
    description: 'Go back to change route',
  },
  profileButtonView: {
    id: 'profile-button-view',
    defaultMessage: 'View',
    description: 'View',
  },
  profileButtonEdit: {
    id: 'profile-button-edit',
    defaultMessage: 'Edit',
    description: 'Edit',
  },
  profileButtonRemove: {
    id: 'profile-button-remove',
    defaultMessage: 'Remove',
    description: 'Remove',
  },
  profileAddNewGroupButton: {
    id: 'profile.add-new-group-button',
    defaultMessage: 'Add new group',
    description: 'Add new group',
  },
  profileOwnerMe: {
    id: 'profile.owner-me',
    defaultMessage: 'Me',
    description: 'Me',
  },

  profileNoGroupsPlaceholder: {
    id: 'profile.no-groups-placeholder',
    defaultMessage: 'Looks like you don’t have any saved Groups yet.',
    description: 'Looks like you don’t have any saved Groups yet.',
  },
  profileAddNewGroupPlaceholder: {
    id: 'profile.add-new-group-popup.placeholder',
    defaultMessage: 'Add from saved passengers data',
    description: 'Add from saved passengers data',
  },

  ticketPrintedTicketDate: {
    id: 'ticket-date-printed',
    defaultMessage: 'Date',
    description: 'Date',
  },
  ticketPrintedTicketDirection: {
    id: 'ticket-direction-printed',
    defaultMessage: 'Direction',
    description: 'Direction',
  },
  ticketPrintedPreviousTicket: {
    id: 'ticket-previous-printed',
    defaultMessage: 'Previous e-tickets',
    description: 'Previous e-tickets',
  },
  ticketPrintedCancelledTicket: {
    id: 'ticket-cancelled-printed',
    defaultMessage: 'Cancelled e-tickets',
    description: 'Cancelled e-tickets',
  },
  ticketPrintedEticket: {
    id: 'ticket-e-ticket-printed',
    defaultMessage: 'E-ticket',
    description: 'E-ticket',
  },
  fullNameLabel: {
    id: 'common-fields.full-name',
    defaultMessage: 'Full name',
    description: 'Full name',
  },
  dateOfBirthLabel: {
    id: 'date-of-birth-controls-label',
    defaultMessage: 'Date of birth',
    description: 'Date of birth',
  },
  genderLabel: {
    id: 'gender-label',
    defaultMessage: 'Date of birth',
    description: 'Date of birth',
  },

  genderMale: {
    id: 'gender-male',
    defaultMessage: 'Male',
    description: 'Male',
  },
  genderFemale: {
    id: 'gender-female',
    defaultMessage: 'Female',
    description: 'Female',
  },
  groupsLabel: {
    id: 'groups-membership-controls-label',
    defaultMessage: 'In group(s)',
    description: 'In group(s)',
  },
  groupsNameLabel: {
    id: 'groups-name-sort-controls-label',
    defaultMessage: 'Groups name',
    description: 'Groups name',
  },
  numberOfGroupsLabel: {
    id: 'number-of-passengers-in-group-label',
    defaultMessage: 'Number of passengers in group',
    description: 'Number of passengers in group',
  },
  savedPassengersTitle: {
    id: 'saved-passengers-data-section-title',
    defaultMessage: 'Saved passengers data',
    description: 'Saved passengers data',
  },
  passengersAndGroupsTitle: {
    id: 'passengers-groups-section-title',
    defaultMessage: 'Passengers and Groups',
    description: 'Passengers and Groups',
  },
  addPassengerBtn: {
    id: 'add-passenger-button-text',
    defaultMessage: 'Add new passenger',
    description: 'Add new passenger',
  },
  addGroupBtn: {
    id: 'add-to-group-button-text',
    defaultMessage: 'Add to group',
    description: 'Add to group',
  },
  dangerousGoods: {
    id: 'note-about-dangerous-goods',
    defaultMessage: 'This sail is shipping Dangerous Goods',
    description: 'warning danger goods',
  },
  noHeavyTracks: {
    id: 'note-about-no-heavy-tracks',
    defaultMessage: 'Heavy trucks (2 tonnes and above) are restricted on this sail',
    description: 'warning no trucks',
  },
  noteAboutCancelled: {
    id: 'note-about-cancelled',
    defaultMessage: 'This sail was cancelled',
    description: 'warning cancelled sail',
  },
  recoveryPasswordPlaceholder: {
    id: 'recovery-fields.password-placeholder',
    defaultMessage: 'Your new password',
    description: 'Your new password',
  },
  recoverySecondPasswordPlaceholder: {
    id: 'recovery-fields.second-password-placeholder',
    defaultMessage: 'Confirm your password',
    description: 'Confirm your password',
  },
  recoveryLinkSentTitle: {
    id: 'recovery.link-sent-title',
    defaultMessage: 'Please check your email or mobile phone for instruction to reset your password.',
    description: 'Instruction to reset password',
  },
  passwordsShouldMatchError: {
    id: 'common-fields.passwords-should-match-error',
    defaultMessage: "Passwords don't match",
    description: "Passwords don't match",
  },
  sessionExpiredHeader: {
    id: 'modal-session-expired-header',
    defaultMessage: 'SESSION EXPIRATION',
    description: 'SESSION EXPIRATION',
  },
  sessionExpired: {
    id: 'modal-session-expired',
    defaultMessage: 'Your session expired, please, start over',
    description: 'Your session expired, please, start over',
  },
  sessionExtend: {
    id: 'modal-extend-my-session',
    defaultMessage: 'Extend my session',
    description: 'Extend my session',
  },
  modalOk: {
    id: 'modal-ok',
    defaultMessage: 'OK',
    description: 'OK',
  },
  sessionWillBeExpired: {
    id: 'modal-session-will-expired',
    defaultMessage: 'Your session is about to expire in 2 minutes',
    description: 'Your session is about to expire in 2 minutes',
  },
  calendarFilterFrom: {
    id: 'calendar-filter-from',
    defaultMessage: 'From',
    description: 'Placeholder from',
  },
  calendarFilterTo: {
    id: 'calendar-filter-to',
    defaultMessage: 'To',
    description: 'Placeholder To',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading...',
    description: 'Loading data',
  },
  privateServiceTitle: {
    id: 'private-service-title',
    defaultMessage: 'This service is Private service, not for public.',
    description: 'Private service',
  },
  infoReturnTicket: {
    id: 'info-return-ticket',
    defaultMessage: 'Return Ticket will be only available if booked on same Vessel/Ferry',
    description: 'Message about return ticket',
  },
  nextDayDepartures: {
    id: 'next-day-departures',
    defaultMessage: '* Next-day departures',
    description: 'Next-day departures',
  },
  invalidDate: {
    id: 'invalid-date',
    defaultMessage: 'Invalid date',
    description: 'Invalid date',
  },
}
