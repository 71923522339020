import { FC } from 'react'
import cn from 'classnames'

interface TabProps {
  title: string
  setTab(tab: string): void
  isActive?: boolean
}

export const Tab: FC<TabProps> = ({ title, isActive, setTab }) => {
  return (
    <h1
      onClick={() => setTab(title)}
      className={cn(
        'cursor-pointer text-sm md:text-base max-w-[100px] md:max-w-full md:w-fit leading-none sm:text-base-normal-lighter',
        {
          'font-bold md:border-b-4 md:border-b-secondary text-white sm:text-white md:text-primary-base': isActive,
          'md:text-primary-base text-primary-base md:font-normal md:pb-2': !isActive,
        }
      )}
    >
      {title}
    </h1>
  )
}
