import { FC, HTMLAttributes } from 'react'
import cn from 'classnames'

interface FormFieldContainerProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  id: string
  maxWidth?: 'lg' | 'full'
  isEditable?: boolean
  textLeft?: 'text-start' | 'text-end'
}

export const FormFieldContainer: FC<FormFieldContainerProps> = ({
  label,
  children,
  id,
  maxWidth = 'lg',
  isEditable = false,
  textLeft = 'text-start',
  ...rest
}) => {
  const showAsterisk = isEditable && !['firstName', 'lastName', 'gender', 'dateOfBirth'].includes(id)

  return (
    <div
      className={cn('w-full grid grid-cols-2 gap-4 items-center', {
        'md:max-w-lg': maxWidth === 'lg',
        'md:max-w-full': maxWidth === 'full',
      })}
      {...rest}
    >
      <label
        htmlFor={id}
        data-testid={`label-${id}`}
        className={cn('w-full max-w-40 pt-3 md:pe-2 lg:pe-0 self-start', {
          'text-start': textLeft === 'text-start',
          'text-end': textLeft === 'text-end',
          'text-base-normal-lighter': textLeft === 'text-end',
          'text-[15px]': textLeft === 'text-end',
        })}
      >
        {label}
        {showAsterisk && <strong className="text-alert-dark">*</strong>}
      </label>
      <div className="w-full">{children}</div>
    </div>
  )
}
