import useSWR from 'swr'
import axios, { AxiosError } from 'axios'
import { SailContent } from '@/root/shared-types'

type UseGetSailsParams = { sailPackage: string; date: string }

const defaultSails: SailContent[] = []

const fetchSails = async ({ sailPackage, date }: UseGetSailsParams): Promise<SailContent[]> => {
  if (!sailPackage || !date) {
    return defaultSails
  }

  try {
    const { data } = await axios.get<SailContent[]>(`/api/sails/${sailPackage}/${date}`)
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error
    }
    throw new Error('Unknown error occurred')
  }
}

export const useGetSails = ({ sailPackage, date }: UseGetSailsParams) => {
  const key = sailPackage && date ? `get-sails ${sailPackage} ${date}` : null
  const {
    data: sailContent = defaultSails,
    error,
    isLoading,
  } = useSWR<SailContent[], AxiosError>(key, () => fetchSails({ sailPackage, date }), {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })

  return {
    sailContent,
    error,
    isLoading,
  }
}
