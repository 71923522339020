import { FC } from 'react'
import { TaxiRouteInfo } from '@/root/modules/admaritime/SchedulePage/components/TaxiRouteInfo/TaxiRouteInfo'

import { Schedule } from '@/root/shared-types/sales-static/sales-static-types'
import { TaxiTableHeader } from '@/root/modules/admaritime/SchedulePage/components/TaxiTableHeader/TaxiTableHeader'
import { TaxiTableTimes } from '@/root/modules/admaritime/SchedulePage/components/TaxiTableTimes/TaxiTableTimes'

interface TaxiTableProps extends Schedule {
  cols: { key: string; label: string }[]
}

export const TaxiTable: FC<TaxiTableProps> = ({ direction, options, cols }) => (
  <>
    {options.map((opt) => (
      <div key={opt.title} className="border-neutral-very-light border-b">
        <h1 className="text-base-medium-light mb-6 flex justify-center text-xl font-bold">{opt.title}</h1>
        <TaxiTableHeader cols={cols} />
        <div className="grid grid-cols-2 text-base">
          <TaxiRouteInfo direction={direction} duration={opt.duration} />
          <TaxiTableTimes times={opt.times} />
        </div>
      </div>
    ))}
  </>
)
