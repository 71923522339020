import { FieldValues, useForm } from 'react-hook-form'
import { useState } from 'react'
import { useCreateOrEditFamily, useFixRHFAutocomplete, useGetTranslations, useModal } from '@/root/shared-hooks'
import { CreatePassengerProps, Customer, DropdownOption, Group, Member, RouteDropdownOption } from '@/root/shared-types'
import { useDropdown } from '@/root/shared-hooks'
import {
  defaultValuesDefiner,
  fieldValuesContentAndFieldsIdsDefiner,
  getNameFromCustomer,
} from '@/root/components/admaritime/ProfilePopUp/helpers/helpers'
import * as R from 'ramda'
import { DateTime } from 'luxon'
import { dateFormats } from '@/root/shared-consts'

interface useGetAddToGroupPopupProps {
  type: 'addToGroup'
  groups: Group[]
  customer?: Customer
}

interface useGetAddNewGroupPopupProps {
  type: 'addNewGroup' | 'editGroup'
  customers: Customer[]
  members?: Member[]
  group?: Group
  customerIds?: number[]
}

interface useGetAddNewPassengerPopupProps {
  type: 'addNewPassenger' | 'editPassenger'
  groups: Group[]
  customer?: Customer
}

type UseGetProfilePopUpProps =
  | (useGetAddNewGroupPopupProps & { customer?: never })
  | useGetAddToGroupPopupProps
  | useGetAddNewPassengerPopupProps

const { DD_MM_YYYY } = dateFormats
const formatToDDMMYYYY = (date: string) => DateTime.fromISO(date).toFormat(DD_MM_YYYY)

export const useGetProfilePopUpProps = (props: UseGetProfilePopUpProps) => {
  const { getTranslation } = useGetTranslations()
  const { createPassenger, createFamily } = useCreateOrEditFamily()
  const { onClose } = useModal()
  const { type } = props

  const [isLoading, setIsLoading] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<RouteDropdownOption>({ name: { ar: '', en: '' }, id: '' })
  const [selectedGroups, setSelectedGroups] = useState<RouteDropdownOption[]>(
    (['addNewPassenger', 'editPassenger'].includes(type) ? props.customer?.companies || [] : []).map(
      ({ companyId, legalName }) => ({ id: companyId, name: { en: legalName } })
    )
  )
  const [selectedCustomer, setSelectedCustomer] = useState<RouteDropdownOption>({ name: { ar: '', en: '' }, id: '' })
  const [selectedCustomers, setSelectedCustomers] = useState<RouteDropdownOption[]>(
    (type === 'addNewGroup' || type === 'editGroup' ? (props as useGetAddNewGroupPopupProps).members || [] : []).map(
      ({ customerId, fullName, birthday }) => ({
        id: customerId as number,
        name: {
          en: `${fullName} (${birthday ? formatToDDMMYYYY(birthday) : ''})`,
        },
      })
    )
  )

  const defaultValuesRest = ['addNewPassenger', 'editPassenger'].includes(type)
    ? { customer: props.customer }
    : type === 'addNewGroup' || type === 'editGroup'
    ? {
        members: (props as useGetAddNewGroupPopupProps).members,
        groupName: (props as useGetAddNewGroupPopupProps).group?.legalName,
      }
    : {}

  const defaultValues = defaultValuesDefiner({ type, ...defaultValuesRest })

  const {
    watch,
    control,
    formState: { errors },
    trigger,
    getValues,
  } = useForm<FieldValues>({ defaultValues })

  useFixRHFAutocomplete({ getValues, trigger, watch })

  let dropdownOptions: DropdownOption[] = []
  let onSelectOption: (option: DropdownOption) => void = () => void 0

  if (type === 'addToGroup' || type === 'addNewPassenger' || type === 'editPassenger') {
    dropdownOptions = props.groups.map(({ companyId, legalName }) => ({ id: companyId, name: { en: legalName } }))
    onSelectOption = (group: DropdownOption) =>
      setSelectedGroups((prev) =>
        prev.some((g) => g.id === group.id) ? prev.filter((g) => g.id !== group.id) : [...prev, group]
      )
  } else if (type === 'addNewGroup' || type === 'editGroup') {
    dropdownOptions = (props as useGetAddNewGroupPopupProps).customers.map(({ customerId, fullName, birthday }) => ({
      id: customerId as number,
      name: {
        en: `${fullName} (${birthday ? formatToDDMMYYYY(birthday) : ''})`,
      },
    }))
    onSelectOption = (customer: DropdownOption) =>
      setSelectedCustomers((prev) =>
        prev.some((c) => c.id === customer.id) ? prev.filter((c) => c.id !== customer.id) : [...prev, customer]
      )
  }

  const returnGroupDropdown = ['addToGroup', 'addNewPassenger', 'editPassenger'].includes(type)
  const dropdownProps = useDropdown({
    options: dropdownOptions,
    selectedOption: returnGroupDropdown ? selectedGroup : selectedCustomer,
    setSelectedOption: returnGroupDropdown ? setSelectedGroup : setSelectedCustomer,
    placeHolder: '',
    onSelectOption,
    selectedOptions: returnGroupDropdown ? selectedGroups : selectedCustomers,
  })

  const titles = {
    addToGroup: getTranslation('profileAddToGroupPopUpTitle'),
    addNewGroup: getTranslation('profileAddNewGroupPopUpTitle'),
    editGroup: getTranslation('profileEditGroupPopUpTitle'),
    addNewPassenger: getTranslation('profileAddNewPassengerPopUpAddTitle'),
    editPassenger: getTranslation('profileAddNewPassengerPopUpAddTitle'),
  }

  const isUnchanged = R.equals(defaultValues, getValues())
  const isDropdownOptionsUnchanged = ['addNewPassenger', 'editPassenger'].includes(type)
    ? R.equals(
        selectedGroups.map(({ id }) => id),
        props.customer?.companies?.map(({ companyId }) => companyId)
      )
    : R.equals(
        selectedCustomers.map(({ id }) => id),
        (props as useGetAddNewGroupPopupProps).members?.map(({ customerId }) => customerId as number) || []
      )

  const isDisabledOnSaveDefiner = {
    addToGroup: selectedGroups.length === 0,
    addNewGroup: !Object.values(getValues()).every((value) => value !== undefined && value !== ''),
    addNewPassenger: !Object.values(getValues()).every((value) => value !== undefined && value !== ''),
    editPassenger:
      !Object.values(getValues()).every((value) => value !== undefined && value !== '') ||
      (isUnchanged && isDropdownOptionsUnchanged),
    editGroup: isUnchanged && isDropdownOptionsUnchanged,
  }

  const onClick = async () => {
    setIsLoading(true)
    if (type === 'addNewGroup' || type === 'editGroup') {
      const customerIds = selectedCustomers.map(({ id }) => id as number)
      await createFamily({
        type: 'FAMILY',
        legalName: getValues('groupName'),
        companyId: (props as useGetAddNewGroupPopupProps).group?.companyId,
        customerIds,
      })
    } else if (type === 'addNewPassenger' || type === 'addToGroup' || type === 'editPassenger') {
      const { customer } = props
      const customerInfo =
        type === 'addToGroup'
          ? {
              firstName: getNameFromCustomer(customer).firstName,
              lastName: getNameFromCustomer(customer).lastName,
              birthday: customer?.birthday,
              gender: customer?.gender,
            }
          : getValues()

      const id = props.customer?.customerId ? { id: { customerBoraId: props.customer?.customerId } } : {}
      await createPassenger({
        ...customerInfo,
        companyIds: selectedGroups.map(({ id }) => id),
        ...id,
      } as CreatePassengerProps)
    }
    onClose()
  }

  const [selectedDate, setSelectedDate] = useState<Date>()
  const handleDayClick = setSelectedDate

  return {
    fieldsIds: fieldValuesContentAndFieldsIdsDefiner({ type, getTranslation })?.fieldsIds,
    fieldValuesContent: fieldValuesContentAndFieldsIdsDefiner({ type, getTranslation })?.fieldValuesContent,
    control,
    errors,
    trigger,
    getValues,
    disabled: isDisabledOnSaveDefiner[type],
    title: titles[type],
    dropdownProps,
    onClick,
    isLoading,
    selectedDate,
    onDayClick: handleDayClick,
  }
}
