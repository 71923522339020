import React, { Dispatch, useEffect, useRef, useState, useMemo } from 'react'
import { DropdownOption } from '@/root/shared-types'

interface useDropdownProps {
  options: DropdownOption[]
  selectedOption: DropdownOption
  setSelectedOption: Dispatch<React.SetStateAction<DropdownOption>>
  placeHolder: string
  onSelectOption?: (option: DropdownOption) => void
  selectedOptions?: DropdownOption[]
  variant?: 'default' | 'filterSelected'
}

export const useDropdown = ({
  setSelectedOption,
  options,
  selectedOption,
  placeHolder,
  onSelectOption,
  selectedOptions = [],
  variant = 'default',
}: useDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const handleOptionSelection = (option: DropdownOption) => {
    if (selectedOptions.some((selected) => selected.id === option.id)) {
      setSelectedOption({ id: '', name: { en: '', ar: '' } })
      onSelectOption?.(option)
    } else {
      setSelectedOption(option)
      onSelectOption?.(option)
    }
    setIsOpen(false)
  }

  const availableOptions = useMemo(() => {
    return variant === 'filterSelected'
      ? options.filter((option) => !selectedOptions.some((selected) => selected.id === option.id))
      : options
  }, [options, selectedOptions, variant])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return {
    isOpen,
    selectedOption,
    options: availableOptions,
    handleToggleDropdown,
    handleOptionSelection,
    dropdownRef,
    placeHolder,
    selectedOptions,
  }
}
