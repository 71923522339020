import { FC, InputHTMLAttributes, useState, useRef, useEffect } from 'react'
import { FieldErrors, FieldValues, UseFormRegister, UseFormTrigger } from 'react-hook-form'
import DayPicker from '@/root/components/shared/DayPicker/DayPicker'
import { useGetTranslations } from '@/root/shared-hooks'
import { FormFieldContainer } from '@/root/components/admaritime/CustomerFormField/FormFieldContainer/FormFieldContainer'
import { Key } from '@/root/shared-hooks/useGetTranslations/useGetTranslations'
import { DateTime } from 'luxon'

interface DateFormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<FieldValues>
  label: string
  errors: FieldErrors
  trigger: UseFormTrigger<FieldValues>
  maxWidth?: 'lg' | 'full'
  isEditable?: boolean
  textLeft?: 'text-start' | 'text-end'
  selectedDate?: Date
  onDayClick?(date: Date): void
}

const DATE_FORMAT_LENGTH = 10

const validateDate = (date: string, getTranslation: (key: Key) => string) => {
  const parsedDate = DateTime.fromFormat(date, 'dd-MM-yyyy')

  if (!parsedDate.isValid) {
    return { isValid: false, error: getTranslation('profileBirthDateInvalidDateText' as Key) }
  }

  const today = DateTime.local()

  if (parsedDate > today) {
    return { isValid: false, error: getTranslation('profileBirthDateInvalidDateText') }
  }

  return { isValid: true }
}

export const DateFormField: FC<DateFormFieldProps> = ({
  label,
  register,
  errors,
  trigger,
  maxWidth,
  isEditable = true,
  textLeft,
  selectedDate,
  onDayClick,
}) => {
  const [isCalendarOpen, setCalendarOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>(
    selectedDate ? selectedDate.toLocaleDateString('en-GB').replace(/\//g, '-') : ''
  )
  const calendarRef = useRef<HTMLDivElement>(null)
  const { getTranslation } = useGetTranslations()

  const toggleCalendar = () => setCalendarOpen((prev) => !prev)

  const handleDayClick = (date: Date) => {
    onDayClick?.(date)
    const formattedDate = date.toLocaleDateString('en-GB').replace(/\//g, '-')
    setInputValue(formattedDate)
    setCalendarOpen(false)
    trigger('birthday')
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setInputValue(newValue)

    if (newValue.length === DATE_FORMAT_LENGTH) {
      const { isValid, error } = validateDate(newValue, getTranslation)
      if (isValid && typeof onDayClick === 'function') {
        const [day, month, year] = newValue.split('-')
        const parsedDate = new Date(Number(year), Number(month) - 1, Number(day))
        onDayClick(parsedDate)
      } else if (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    if (inputValue) {
      trigger('birthday')
    }
  }, [inputValue, trigger])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
        setCalendarOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <FormFieldContainer id="date" label={label} maxWidth={maxWidth} isEditable={isEditable} textLeft={textLeft}>
      <div>
        <input
          type="text"
          value={inputValue}
          placeholder={getTranslation('profileBirthDatePlaceholder')}
          onClick={toggleCalendar}
          {...register('birthday', {
            required: true,
            validate: {
              correctFormatAndDate: (value) => {
                const { isValid, error } = validateDate(value, getTranslation)
                return isValid || error
              },
            },
            onChange: handleInputChange,
          })}
          className="border-neutral-normal-lighter h-12 w-full rounded border p-2"
        />
        {errors.birthday && (
          <p className="text-error flex justify-center pt-2 text-xs font-bold">
            {errors.birthday.message && String(errors.birthday.message)}
          </p>
        )}
        {isCalendarOpen && (
          <div ref={calendarRef} className="mt-2">
            <DayPicker
              selectedDate={selectedDate}
              onDayClick={handleDayClick}
              isDayDisabled={(day) => day > new Date()}
            />
          </div>
        )}
      </div>
    </FormFieldContainer>
  )
}
