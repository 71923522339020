import { FC } from 'react'
import cn from 'classnames'

interface TableCellProps {
  label: string
  isHeader?: boolean
}

export const TableCell: FC<TableCellProps> = ({ isHeader, label }) => (
  <p
    className={cn(
      'flex justify-center items-center',
      { 'text-secondary font-semibold': isHeader },
      { 'last:border-s last:border-s-neutral-very-light': !isHeader }
    )}
  >
    {label}
  </p>
)
