import { salesStaticService } from '@/root/shared-api'
import useSWR from 'swr/immutable'
import { useCustomRouter } from '@/root/shared-hooks'

export function useFooterItems() {
  const { locale } = useCustomRouter()
  const { data, error } = useSWR(`/footer/${locale}`, () => salesStaticService.getFooterItems(locale))
  return {
    footerItems: data,
    error,
  }
}
