import { FC, useState } from 'react'
import { Reservation, ReservationFilter } from '@/root/modules/admaritime/CustomerInformation'
import { Reservation as ReservationProps } from '@/root/shared-types'

interface FilterWithReservationsProps {
  reservations: ReservationProps[]
}

const FilterWithReservations: FC<FilterWithReservationsProps> = ({ reservations }) => {
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()
  const [ticketNumber, setTicketNumber] = useState("")
  const [previousETicketsChecked, setPreviousETicketsChecked] = useState(false)
  const [cancelledETicketsChecked, setCancelledETicketsChecked] = useState(false)

  const filteredReservations = reservations.filter(reservation => {
    const arrivalDate = new Date(reservation.arrivalAt || '')
    const isAfterFromDate = fromDate ? arrivalDate >= fromDate : true
    const isBeforeToDate = toDate ? arrivalDate <= toDate : true
    const matchesTicketNumber = reservation.reservationId.toString().includes(ticketNumber)

    const isCancelled = reservation.status === 'CN'
    const isPrevious = arrivalDate < new Date()

    if (previousETicketsChecked && cancelledETicketsChecked) {
      return isAfterFromDate && isBeforeToDate && matchesTicketNumber
    } else if (previousETicketsChecked && !isCancelled) {
      return isPrevious
    } else if (cancelledETicketsChecked && isCancelled) {
      return isCancelled && isAfterFromDate
    } else {
      return !isCancelled && !isPrevious && isAfterFromDate && isBeforeToDate && matchesTicketNumber
    }
  }).sort((a, b) => new Date(b.arrivalAt || '').getTime() - new Date(a.arrivalAt || '').getTime())
  
  return (
    <main className="flex w-full flex-col gap-3">
      <ReservationFilter 
      setFromDate={setFromDate} 
      setToDate={setToDate} 
      ticketNumber={ticketNumber} 
      setTicketNumber={setTicketNumber}         
      previousETicketsChecked={previousETicketsChecked}
      setPreviousETicketsChecked={setPreviousETicketsChecked}
      cancelledETicketsChecked={cancelledETicketsChecked}
      setCancelledETicketsChecked={setCancelledETicketsChecked}/>
      {filteredReservations.length > 0 && filteredReservations.map((res) => (
        <Reservation reservation={res} key={res.reservationId} />
      ))}
    </main>
  )
}

export default FilterWithReservations
