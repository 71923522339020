import useSWR from 'swr'
import axios, { AxiosError } from 'axios'
import { Inventory } from '@/root/shared-types'
import { defaultInventory } from '@/root/shared-consts'

interface UseGetInventoryParams {
  locale: string
  sailPackage: string
  date: string
}

const fetchInventory = async ({ locale, sailPackage, date }: UseGetInventoryParams): Promise<Inventory> => {
  if (!sailPackage || !date) {
    return Promise.resolve(defaultInventory)
  }

  try {
    const { data } = await axios.get<Inventory>(`/api/inventory/${sailPackage}/${date}`, {
      headers: { 'Accept-Language': locale },
    })
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw error
    }
    throw new Error('Unknown error occurred')
  }
}

export const useGetInventory = ({ locale, sailPackage, date }: UseGetInventoryParams) => {
  const key = sailPackage && date ? `get-inventory ${sailPackage} ${date}` : null

  const {
    data: inventory = defaultInventory,
    error,
    isLoading,
  } = useSWR<Inventory, AxiosError>(key, () => fetchInventory({ locale, sailPackage, date }), {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })

  return {
    inventory,
    error,
    isLoading,
  }
}
