import { useCustomRouter, useReservation } from '@/root/shared-hooks'
import { getTermsByLocale } from '@/root/shared-helpers'
import { FC } from 'react'
import DOMPurify from 'dompurify'

const TicketTerms: FC = () => {
  const { reservation } = useReservation()
  const { locale } = useCustomRouter()

  if (!reservation) {
    return null
  }

  const terms = getTermsByLocale(reservation.terms, locale)

  if (!terms) return null

  return (
    <div className="pt-8 text-sm">
      <div
        className="[&>p]:my-3 [&>ul]:list-disc [&>ul]:ps-10"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(terms.ticketTerms) }}
      />
      <div
        className="pt-5 [&>p>a]:underline [&>p]:my-3"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(terms.additionalTicketTerms),
        }}
      />
    </div>
  )
}

export default TicketTerms
