import { FC, InputHTMLAttributes } from 'react'
import { FieldErrors, FieldValues, UseFormRegister, UseFormTrigger } from 'react-hook-form'
import { FormField } from '@/root/components/admaritime/CustomerFormField/FormField/FormField'
import { useGetTranslations } from '@/root/shared-hooks'
import { FormFieldContainer } from '@/root/components/admaritime/CustomerFormField/FormFieldContainer/FormFieldContainer'
import cn from 'classnames'
import { bluewaveMessages } from '@/lang/bluewave/messages'
import { admaritimeMessages } from '@/lang/admaritime/messages'

export interface PhoneNumberFormField extends InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegister<FieldValues>
  label: string
  codeId: string
  phoneId: string
  errors: FieldErrors
  trigger: UseFormTrigger<FieldValues>
  onBlur?(): void
  errorMessage?: string
  maxWidth?: 'lg' | 'full'
  isEditable?: boolean
  isCustomerDetails?: boolean
  textLeft?: 'text-start' | 'text-end'
}

export type Key = keyof typeof bluewaveMessages | keyof typeof admaritimeMessages

export const PhoneNumberFormField: FC<PhoneNumberFormField> = ({
  label,
  phoneId,
  codeId,
  register,
  errors,
  trigger,
  onBlur,
  className,
  maxWidth,
  isEditable = true,
  textLeft,
  isCustomerDetails = true,
}) => {
  const { getTranslation } = useGetTranslations()

  const phonePlaceholder = isCustomerDetails ? getTranslation('confirmationContactFormPhonePlaceHolder') : ''
  const codePlaceholder = isCustomerDetails ? getTranslation('confirmationContactFormCodePlaceHolder') : ''

  return (
    <FormFieldContainer id={phoneId} label={label} maxWidth={maxWidth} isEditable={isEditable} textLeft={textLeft}>
      <span
        className={cn(
          { 'grid grid-cols-[20%_1fr] gap-5': isEditable },
          { 'gap-0 grid': !isEditable },
          {
            'grid-cols-[20%_1fr] sm:grid-cols-[10%_1fr] md:grid-cols-[12%_1fr] lg:grid-cols-[11%_1fr]':
              !isCustomerDetails,
          },
          className
        )}
      >
        <label htmlFor={codeId} className="sr-only">
          {getTranslation('confirmationContactFormCodeLabel' as Key)}
        </label>
        <FormField
          register={register}
          id={codeId}
          validationPattern={/^\+?\d{1,4}$/}
          maxLength={5}
          minLength={1}
          placeholder={codePlaceholder}
          errors={errors}
          inputMode="numeric"
          trigger={trigger}
          onBlur={onBlur}
          className={className}
        />

        <label htmlFor={phoneId} className="sr-only">
          {getTranslation('confirmationContactFormPhoneLabel' as Key)}
        </label>
        <FormField
          register={register}
          id={phoneId}
          placeholder={phonePlaceholder}
          errors={errors}
          trigger={trigger}
          inputMode="numeric"
          pattern="d*"
          validationPattern={/^\d{7,15}$/}
          maxLength={15}
          minLength={7}
          onBlur={onBlur}
          className={className}
        />
      </span>
    </FormFieldContainer>
  )
}
