import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useAuth, useCustomRouter, useGetAuthToken, useGetTranslations } from '@/root/shared-hooks'
import { HeaderNavBarProps } from '@/root/shared-types'
import { Button } from '@/root/ui/admaritime'
import { ContentContainer } from '@/root/components/shared'
import Link from 'next/link'
import Image from 'next/image'
import { APP_ROUTES } from '@/root/shared-consts'
import { Hamburger } from '@/root/ui/admaritime/Hamburger/Hamburger'
import { useGetPassengersData } from '@/root/shared-hooks'
import { useRouter } from 'next/router'

interface MainHeaderNavBarProps extends HeaderNavBarProps {
  mobileMenuOpen: boolean
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>
}

const MainHeaderNavBar: FC<MainHeaderNavBarProps> = ({ menuItems, mobileMenuOpen, setMobileMenuOpen }) => {
  const { getTranslation } = useGetTranslations()
  const { isAuth } = useGetAuthToken()
  const { logOut } = useAuth()
  const { push } = useCustomRouter()
  const { data } = useGetPassengersData()
  const { locale } = useRouter()

  const fullName = data?.passengers?.[0]?.fullName ?? 'User'

  const logoSrc = locale === 'ar' ? '/admaritime/images/logo_AR.png' : '/admaritime/images/logo.png'

  return (
    <div className="bg-white">
      <ContentContainer>
        <div className="mb-4 flex h-28 justify-between pe-6 ps-3 md:mb-0 md:h-32 lg:pe-[30px]">
          <Link href="/" className="darken flex w-60 items-center md:shrink-0 lg:w-fit">
            <Image alt="admaritime logo" src={logoSrc} width="275" height="84" />
          </Link>

          <nav className="flex flex-col justify-center">
            {!mobileMenuOpen && <Hamburger onClick={() => setMobileMenuOpen(true)} />}

            <ul className="text-primary-base hidden items-center text-base font-bold md:flex">
              {menuItems.map(({ id, link, subject, className }) => {
                if (className === 'login') {
                  return (
                    <li key={id} className="ms-5 shrink-0 lg:ms-0">
                      {isAuth ? (
                        <Button variant="contained" onClick={logOut}>
                          {getTranslation('headerLogoutButtonLabel')}
                        </Button>
                      ) : (
                        <Button variant="contained" onClick={() => push(APP_ROUTES.signup)}>
                          {getTranslation('headerRegisterOrLoginButton')}
                        </Button>
                      )}
                    </li>
                  )
                }

                return (
                  <li key={id} className="darken ms-5 text-center lg:ms-0 lg:px-7">
                    <Link href={link}>{subject}</Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        </div>

        {isAuth && (
          <p className="text-secondary pb-2 pe-6 pr-7 ps-3 text-end text-xs font-bold lg:pe-[30px]">
            {getTranslation('headerLoginButtonLabel')} <span className="uppercase">{fullName}</span>!
          </p>
        )}
      </ContentContainer>
    </div>
  )
}

export default MainHeaderNavBar
