import axios, { AxiosError } from 'axios'
import { useSWRConfig } from 'swr'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DateTime } from 'luxon'

interface CreateFamilyProps {
  type: 'FAMILY'
  legalName: string
  companyId?: number
  customerIds?: number[]
}

interface AddToFamilyProps {
  companyId: number
  customersIds: number[]
}

interface CreatePassengerProps {
  firstName: string
  lastName: string
  birthday: string
  gender: 'M' | 'F'
  companyIds: string[]
}

const DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/

const formatDateForServer = (date: string | undefined): string => {
  if (!date) {
    throw new Error('Invalid date: date is undefined')
  }

  if (DATE_FORMAT_REGEX.test(date)) {
    return date
  }

  const parsedDate = DateTime.fromFormat(date, 'dd-MM-yyyy')

  if (!parsedDate.isValid) {
    throw new Error('Invalid date format: expected dd-MM-yyyy')
  }

  return parsedDate.toFormat('yyyy-MM-dd')
}

export const useCreateOrEditFamily = () => {
  const { mutate } = useSWRConfig()

  const mutatePassengersAndGroups = () => {
    mutate('passengersData')
  }

  const handleError = (error: unknown) => {
    const axiosError = error as AxiosError
    toast.error(`Error: ${axiosError.message || 'Unknown error occurred'}`)
    if (axiosError.response) {
      toast.error(`Server response: ${JSON.stringify(axiosError.response.data)}`)
    }
    mutatePassengersAndGroups()
  }

  const createFamily = async (body: CreateFamilyProps) => {
    const formattedBody = {
      ...body,
      customerIds: body.customerIds?.map(String) ?? [],
    }

    try {
      await axios.put(`/api/user/create-or-edit/company`, formattedBody)
      mutatePassengersAndGroups()
    } catch (error) {
      handleError(error)
    }
  }

  const createPassenger = async (body: CreatePassengerProps) => {
    const formattedBody = {
      ...body,
      birthday: formatDateForServer(body.birthday),
      companyIds: body.companyIds.map(String),
    }

    if (!formattedBody.firstName || !formattedBody.lastName || !formattedBody.birthday || !formattedBody.gender) {
      toast.error('Missing required fields, please, check again')
      return
    }

    try {
      await axios.put(`/api/user/create-or-edit/customer`, formattedBody)
      mutatePassengersAndGroups()
    } catch (error) {
      handleError(error)
    }
  }

  const addToFamily = async ({ customersIds, companyId }: AddToFamilyProps) => {
    const formattedIds = customersIds.map(String)

    try {
      await axios.put(`/api/user/${companyId}/${formattedIds.join(',')}`)
      mutatePassengersAndGroups()
    } catch (error) {
      handleError(error)
    }
  }

  const deleteFamily = async (id: number) => {
    try {
      await axios.delete(`/api/user/delete-company-or-customer`, {
        data: { id, type: 'company' },
      })
      mutatePassengersAndGroups()
    } catch (error) {
      handleError(error)
    }
  }

  const deletePassenger = async (id: number) => {
    try {
      await axios.delete(`/api/user/delete-company-or-customer`, {
        data: { id, type: 'customer' },
      })
      mutatePassengersAndGroups()
    } catch (error) {
      handleError(error)
    }
  }

  return { createFamily, createPassenger, addToFamily, deleteFamily, deletePassenger }
}
