import { FC } from 'react'

export const GroupBadge: FC<{ title: string }> = ({ title }) => (
  <div className="text-primary-base whitespace-normal break-all font-bold">
    <span className="flex items-center justify-center">
      <span className="inline-block rounded-lg bg-adm-accent-normalLighter px-2 py-1 text-xs">
        {title}
      </span>
    </span>
  </div>
)
