import { FC, useEffect, useState } from 'react'
import Image from 'next/image'
import { Instruction } from '@/root/shared-types'
import ImageViewer from 'react-simple-image-viewer'

import ReactDOM from 'react-dom'

export const InstructionContent: FC<Instruction> = ({ title, images }) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)
  const imagesForViewer = images?.map(({ src }) => src) || []
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setModalRoot(document.getElementById('modal-root'))
  }, [])

  useEffect(() => {
    if (isViewerOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isViewerOpen])

  const openImageViewer = (index: number) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <div className="w-full flex-col space-y-3">
      <p className="text-neutral-very-light ms-12 max-w-[90%] text-start font-thin">{title}</p>
      <div className="flex flex-col items-center space-y-2">
        {images?.map((image, index) => (
          <div className="flex flex-col justify-center" key={image.src}>
            {image.title && <p className="text-neutral-very-light mt-5 text-start font-thin">{image.title}</p>}
            <Image
              onClick={() => openImageViewer(index)}
              src={image.src}
              alt={image.alt || 'instruction image'}
              width={image.width}
              height={image.height}
              className="cursor-pointer"
            />
          </div>
        ))}
      </div>
      {isViewerOpen &&
        modalRoot &&
        ReactDOM.createPortal(
          <ImageViewer
            src={imagesForViewer}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            backgroundStyle={{ marginTop: 0, direction: 'ltr', zIndex: 100 }}
          />,
          modalRoot
        )}
    </div>
  )
}
