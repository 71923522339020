import { useConfig } from './config/useConfig'
import { useFooterItems } from './sales-static/useFooterItems'
import { useMenuItems } from './sales-static/useMenuItems'
import { useStaticContent } from './static-content/useStaticContent'
import { useReservation } from './reservation/useReservation'
import { usePrintedTicketData } from './reservation/usePrintedTicketData'
import { useGetTranslations } from '@/root/shared-hooks/useGetTranslations/useGetTranslations'
import { useOpenUserManual } from '@/root/shared-hooks/useOpenUserManual/useOpenUserManual'
import { useGetAvailableDates } from '@/root/shared-hooks/useGetAvailableDates/useGetAvailableDates'
import useIsMobile from '@/root/shared-hooks/useIsMobile/useIsMobile'
import { useTravelDurationMessage } from '@/root/shared-hooks/select-date-time/useTravelDurationMessage'
import { useGetCustomerFields } from '@/root/shared-hooks/useGetCustomerFields/useGetCustomerFields'
import { useSetQrImgSrc } from '@/root/shared-hooks/useSetQrImgSrc/useSetQrImgSrc'
import useScrollToRef from '@/root/shared-hooks/useScrollToRef/useScrollToRef'
import { useCustomRouter } from '@/root/shared-hooks/useCustomRouter/useCustomRouter'
import { useApplePay } from '@/root/shared-hooks/useApplePay/useApplePay'
import { useFixRHFAutocomplete } from '@/root/shared-hooks/useFixRHFAutocomplete/useFixRHFAutocomplete'
import { useModal } from '@/root/shared-hooks/useModal/useModal'
import { useDropdown } from '@/root/shared-hooks/useDropdown/useDropdown'
import { useGetAuthToken } from '@/root/shared-hooks/useGetAuthToken/useGetAuthToken'
import { useSetAuthToken } from '@/root/shared-hooks/useSetAuthToken/useSetAuthToken'
import { useAuth } from '@/root/shared-hooks/useAuth/useAuth'
import { useCreateOrEditFamily } from '@/root/shared-hooks/useAddOrEditFamily/useCreateOrEditFamily'
import { useGetReservationByUser } from '@/root/shared-hooks/useGetReservationsByUser/useGetReservationByUser'
import { useGetPassengersData } from '@/root/shared-hooks/useGetPassengersData/useGetPassengersData'
import { useGetSailPackage } from '@/root/shared-hooks/useGetSailPackage/useGetSailPackage'
import { useAllAndSelectedPackage } from '@/root/shared-hooks/useAllAndSelectedPackage/useAllAndSelectedPackage'
import { useGetInventory } from '@/root/shared-hooks/useGetInventory/useGetInventory'
import { useGetSails } from '@/root/shared-hooks/useGetSails/useGetSails'
import { useAppConfig } from '@/root/shared-hooks/appConfig/useAppConfig'
import { useErrorBanner } from '@/root/shared-hooks/useErrorBanner/useErrorBanner'

export {
  useConfig,
  useStaticContent,
  useMenuItems,
  useFooterItems,
  useReservation,
  usePrintedTicketData,
  useGetTranslations,
  useOpenUserManual,
  useGetAvailableDates,
  useIsMobile,
  useTravelDurationMessage,
  useGetCustomerFields,
  useSetQrImgSrc,
  useScrollToRef,
  useCustomRouter,
  useApplePay,
  useFixRHFAutocomplete,
  useModal,
  useDropdown,
  useGetAuthToken,
  useSetAuthToken,
  useAuth,
  useCreateOrEditFamily,
  useGetReservationByUser,
  useGetPassengersData,
  useGetSailPackage,
  useAllAndSelectedPackage,
  useGetInventory,
  useGetSails,
  useAppConfig,
  useErrorBanner,
}
