import { FC, HTMLAttributes } from 'react'
import { Col } from '@/root/shared-types'
import { TableCell } from '@/root/components/admaritime'
import cn from 'classnames'

interface TableHeaderProps extends HTMLAttributes<HTMLDivElement> {
  cols: Col[]
}

export const TableHeader: FC<TableHeaderProps> = ({ cols, className }) => {
  return (
    <div className={cn(`border border-neutral-very-light w-full h-16`, className)}>
      {cols.map(({ key, label }) => (
        <TableCell label={label} key={key} isHeader />
      ))}
    </div>
  )
}
