import { Dispatch, FC, SetStateAction, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import DayPicker from '@/root/components/shared/DayPicker/DayPicker'

interface Props {
    setFromDate: Dispatch<SetStateAction<Date | undefined>>
    setToDate: Dispatch<SetStateAction<Date | undefined>>
    fromDate?: string
    toDate?: string
}
  
const ReservationFilterByDates: FC<Props> = ({ fromDate, toDate, setFromDate, setToDate }) => {
    
    const [isDayPickerOpen, setIsDayPickerOpen] = useState(false)

    const handleFromDateSelection = (date: Date) => {
        setFromDate(date)
        if (toDate && new Date(toDate) < date) {
            setToDate(undefined)
        }
    }

    const handleToDateSelection = (date: Date) => {
        if (date && fromDate && new Date(date) >= new Date(fromDate)) {
            setToDate(date)
        }
    }

    const formatDate = (date: string | undefined) => {
        if (!date || isNaN(Date.parse(date))) return ''
        return new Date(date).toLocaleString('default', { month: 'short' }) + ' ' + new Date(date).getDate()
    }

    const fromDateLabel = formatDate(fromDate)
    const toDateLabel = formatDate(toDate)

    return (
        <div className="text-primary-base relative grid w-full gap-2 text-xs font-bold">
            <section className="border-neutral-normal-light h-8 w-full rounded border bg-white text-sm font-normal focus:outline-none" onClick={() => setIsDayPickerOpen(true)}>
                <div className="text-neutral flex justify-around pt-1.5">
                    <span>{fromDateLabel}</span>
                    <span>{toDateLabel}</span>
                 </div>
            </section>
            {isDayPickerOpen && (
                <div className="absolute top-full z-20 mt-3 rounded-md bg-white shadow-lg">
                    <OutsideClickHandler onOutsideClick={() => setIsDayPickerOpen(false)}>
                        <div className="flex flex-col md:flex-row">
                            <DayPicker
                            selectedDate={fromDate ? new Date(fromDate) : undefined}
                            onDayClick={handleFromDateSelection}
                            isCustomStyle
                            />
                            <DayPicker
                            selectedDate={toDate ? new Date(toDate) : undefined}
                            onDayClick={handleToDateSelection}
                            isCustomStyle
                            />
                        </div>
                    </OutsideClickHandler>
                </div>
            )}
        </div>
    )
}

export default ReservationFilterByDates
