import { FC, useEffect } from 'react'
import { Control, useController } from 'react-hook-form'
import { FormFieldContainer } from '@/root/components/admaritime/CustomerFormField/FormFieldContainer/FormFieldContainer'

interface RadioInputProps {
  control: Control
  options: { id: string; name: string }[]
  id: string
  label: string
}

export const RadioInput: FC<RadioInputProps> = ({ control, options, id, label }) => {
  const { field } = useController({
    name: id,
    control,
    rules: { required: true },
  })

  useEffect(() => {
    if (!field.value) {
      field.onChange('M')
    }
  }, [field])

  return (
    <FormFieldContainer id={id} label={label} maxWidth="full">
      <div className="flex min-h-16 w-full gap-10 pb-4">
        {options.map((opt) => (
          <div key={opt.id} className="flex items-center gap-3">
            <div className="relative flex items-center">
              <input
                {...field}
                value={opt.id}
                type="radio"
                checked={field.value === opt.id}
                onChange={() => field.onChange(opt.id)}
                className="absolute z-10 size-full cursor-pointer opacity-0"
              />
              <div
                className={`relative flex items-center justify-center rounded-full transition-all duration-300 ${
                  field.value === opt.id
                    ? 'border-secondary-medium-lighter size-6 scale-110 border-4 shadow-md'
                    : 'border-neutral-medium-lighter size-5 border-2 shadow-none'
                }`}
              >
                {field.value === opt.id && <div className="absolute size-3 rounded-full bg-white"></div>}
              </div>
            </div>
            <p className="text-neutral-medium-light">{opt.name}</p>
          </div>
        ))}
      </div>
    </FormFieldContainer>
  )
}
