import { FC } from 'react'
import { Tab } from '@/root/ui/admaritime'

interface TariffsTabsProps {
  tabs: string[]
  currentTab: string
  setTab(tab: string): void
}

export const Tabs: FC<TariffsTabsProps> = ({ tabs, currentTab, setTab }) => {
  return (
    <div className="flex w-full flex-row items-center justify-center gap-10 md:items-baseline md:justify-start md:gap-16">
      {tabs.map((tab) => (
        <Tab title={tab} key={tab} isActive={tab === currentTab} setTab={setTab} />
      ))}
    </div>
  )
}
