import {
  Direction,
  DropdownOption,
  LocalizedLocation,
  RouteDropdownOption,
  SailPackage,
  SearchBarDate,
} from '@/root/shared-types'
import { useEffect, useState } from 'react'
import {
  useGetTranslations,
  useGetAvailableDates,
  useIsMobile,
  useCustomRouter,
  useGetSailPackage,
} from '@/root/shared-hooks'
import { useDropdown } from '@/root/shared-hooks/useDropdown/useDropdown'
import { dateToFormattedString } from '@/root/shared-helpers'
import { dateFormats, APP_ROUTES } from '@/root/shared-consts'
import * as R from 'ramda'

export const useGetRouteSearchBarProps = (sailPackages: SailPackage[]) => {
  const isMobile = useIsMobile()
  const router = useCustomRouter()

  const { getTranslation } = useGetTranslations()
  const [selectedRoute, setSelectedRoute] = useState<RouteDropdownOption>({
    name: { ar: '', en: '' },
    id: '',
    extraProps: {
      isRoundTrip: false,
      route: '',
    },
  })
  const [isRoundTripChecked, setIsRoundTripChecked] = useState(false)
  const [selectedDate, setSelectedDate] = useState<SearchBarDate>({})
  const availableDatesTo = useGetAvailableDates({ sailPackage: String(selectedRoute.id) })
  const availableDatesFrom = useGetAvailableDates({ sailPackage: selectedRoute.extraProps?.backwardsSailPackage })

  const [isDayPickerOpened, setIsDayPickerOpened] = useState(false)
  const toggleDayPickers = () => setIsDayPickerOpened((prevState) => !prevState)

  useEffect(() => {
    if (isMobile && !isDayPickerOpened) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isMobile, isDayPickerOpened])

  const handleDateChange = (direction: Direction) => (date: Date) => {
    setSelectedDate((prevDate) => {
      if (direction === 'to' && prevDate.from) {
        if (date > prevDate.from) {
          return { to: date, from: undefined }
        }
      }
      return { ...prevDate, [direction]: date }
    })
    if (direction === 'from' || (direction === 'to' && !isRoundTripChecked)) {
      toggleDayPickers()
    }
  }

  const onRoundTripCheck = () =>
    setIsRoundTripChecked((prevState) => {
      if (!prevState && !selectedDate.from) {
        const defaultPrevDate = availableDatesFrom.find((date) => {
          if (selectedDate.to) {
            return date > selectedDate.to
          }
        })

        if (defaultPrevDate) {
          setSelectedDate((prevDate) => ({ ...prevDate, from: defaultPrevDate }))
        }
      }
      return !prevState
    })

  const sailPackageMap = sailPackages.reduce((map, packageItem) => {
    map.set(packageItem.code, packageItem)
    return map
  }, new Map<string, SailPackage>())

  const routes = sailPackages.map((sailPackage) => {
    const backwardsSailPackage =
      (sailPackage.backwardSailPackage && sailPackageMap.get(sailPackage.backwardSailPackage)) || null
    return {
      id: sailPackage.code,
      name: sailPackage.titles ? sailPackage.titles : { en: sailPackage.title },

      extraProps: {
        isRoundTrip: sailPackage.roundtrip,
        backwardsSailPackage: sailPackage.backwardSailPackage,
        code: sailPackage.route,
        backwardsName: backwardsSailPackage?.titles || { en: backwardsSailPackage?.title },
        routeAttributes: sailPackage.routeAttributes || {},
      },
    }
  })

  const dropdownProps = useDropdown({
    options: routes,
    setSelectedOption: setSelectedRoute,
    selectedOption: selectedRoute,
    placeHolder: getTranslation('searchBarWherePlaceholder'),
  })
  const onSelectRoute = (route: DropdownOption) => {
    dropdownProps.handleOptionSelection(route)
    setSelectedDate((prevState) => R.omit(['from'], prevState))
    setIsRoundTripChecked(false)
  }

  const formatSelectedDate = (date?: Date) => (date ? dateToFormattedString(date, dateFormats.ISO_DATE) : '')

  const dateTo = formatSelectedDate(selectedDate.to)
  const dateFrom = formatSelectedDate(selectedDate.from)

  const isRoundTrip = isRoundTripChecked && Boolean(dateFrom)

  const baseUrl = `${APP_ROUTES.selectDateSail}/${selectedRoute.id}`
  const backRoutePath = isRoundTrip ? `,${selectedRoute.extraProps?.backwardsSailPackage}` : ''
  const datePath = dateTo ? `/${dateTo}${isRoundTrip ? `,${dateFrom}` : ''}` : ''

  const url = `${baseUrl}${backRoutePath}${datePath}`

  const { setSailPackage } = useGetSailPackage()

  const onGoToSelectTicket = () => {
    router.push({
      pathname: url,
    })
    setSailPackage({
      code: String(selectedRoute.id),
      backwardSailPackage: selectedRoute.extraProps?.backwardsSailPackage,
      roundtrip: isRoundTripChecked,
      sailRefs: [],
      route: selectedRoute.extraProps?.route || '',
      title: selectedRoute.name?.en || '',
      titles: selectedRoute.name as LocalizedLocation,
      routeAttributes: selectedRoute.extraProps?.routeAttributes,
    })
  }

  return {
    routes,
    handleDateChange,
    selectedDate,
    onRoundTripCheck,
    isRoundTripChecked,
    selectedRoute,
    setSelectedRoute,
    dropdownProps: { ...dropdownProps, handleOptionSelection: onSelectRoute },
    availableDates: { availableDatesTo, availableDatesFrom },
    sailPackageMap,
    onGoToSelectTicket,
    isDayPickerOpened,
    toggleDayPickers,
  }
}
