import { Customer, InputFieldRecord, PopUpType } from '@/root/shared-types'
import { addNewGroup, addToGroupFields } from '@/root/shared-consts'
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat'
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core'
import { Key } from '@/root/shared-hooks/useGetTranslations/useGetTranslations'

interface FieldValuesContentAndFieldsIdsDefinerProps {
  type: PopUpType
  getTranslation(
    key: Key,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ): string
}

export const fieldValuesContentAndFieldsIdsDefiner = ({
  type,
  getTranslation,
}: FieldValuesContentAndFieldsIdsDefinerProps) => {
  if (type === 'addNewPassenger' || type === 'editPassenger') {
    return {
      fieldValuesContent: {
        firstName: {
          label: getTranslation('customerInformationFistName'),
          type: 'text',
        },
        lastName: {
          label: getTranslation('customerInformationLastName'),
          type: 'text',
        },
        gender: {
          label: getTranslation('genderLabel'),
          type: 'radio',
        },
        birthday: {
          label: getTranslation('dateOfBirthLabel'),
          type: 'date',
        },
      } as InputFieldRecord,
      fieldsIds: [
        addToGroupFields.FIRST_NAME,
        addToGroupFields.LAST_NAME,
        addToGroupFields.GENDER,
        addToGroupFields.DATE_OF_BIRTH,
      ],
    }
  }
  if (type === 'addNewGroup' || type === 'editGroup') {
    return {
      fieldValuesContent: {
        groupName: {
          label: getTranslation('profileAddNewGroupPopUpLabel'),
          type: 'text',
        },
      } as InputFieldRecord,
      fieldsIds: [addNewGroup.GROUP_NAME],
    }
  }
}

interface DefaultValuesAddNewPassenger {
  type: 'addNewPassenger' | 'editPassenger'
  customer?: Customer
}
interface DefaultValuesAddNewGroup {
  type: 'addNewGroup' | 'editGroup'
  groupName?: string
}

interface DefaultValuesAddToGroup {
  type: 'addToGroup'
}

type defaultValuesDefiner = DefaultValuesAddNewPassenger | DefaultValuesAddNewGroup | DefaultValuesAddToGroup

export const defaultValuesDefiner = (props: defaultValuesDefiner) => {
  const { type } = props
  if (type === 'addNewPassenger' || type === 'editPassenger') {
    const { customer } = props
    const { firstName, lastName } = customer ? getNameFromCustomer(customer) : { firstName: '', lastName: '' }
    return { firstName, lastName, birthday: customer?.birthday || '', gender: customer?.gender || '' }
  }
  if (type === 'addNewGroup' || type === 'editGroup') {
    const { groupName } = props
    return { groupName }
  }
  if (type === 'addToGroup') {
    return {}
  }
}

export const getNameFromCustomer = (customer?: Customer) => {
  const fallbackArr = customer?.fullName?.split(' ') ?? []
  const firstName = customer?.firstName ?? fallbackArr[0] ?? ''
  const lastName = customer?.lastName ?? fallbackArr[1] ?? ''

  return { firstName, lastName }
}
