import React, { FC } from 'react'
import { UserIcon } from '@/root/ui/shared/icons/UserIcon'
import { Eticket } from '@/root/ui/shared/icons/Eticket'
import { useGetTranslations } from '@/root/shared-hooks'
import cn from 'classnames'
import { KebabButton } from '@/root/ui/admaritime'
import { KebabMenu } from '@/root/components/admaritime'
import { Reservation as IReservation } from '@/root/shared-types'
import { getDepartureAndArrivalDataFromReservation } from '@/root/shared-helpers'
import { DateTime } from 'luxon'
import { useActionsButtonHandlers } from '@/root/shared-hooks/reservation/useActionsButtonHandlers'
import { dateFormats } from '@/root/shared-consts'

interface ReservationProps {
  reservation: IReservation
}

const Reservation: FC<ReservationProps> = ({
  reservation,
  reservation: { reservationId, reservationOwner, status, allowance },
}) => {
  const { getTranslation } = useGetTranslations()
  const disabled = status === 'CN'

  const isCancelable = allowance?.cancel

  const { departureThere, departureBack, departurePortThere, arrivalPortThere, departurePortBack, arrivalPortBack } =
    getDepartureAndArrivalDataFromReservation(reservation)

  const departureValueThere = DateTime.fromISO(departureThere || '').toFormat(dateFormats.DETAILED_DATE)
  const departureValueBack = departureBack
    ? DateTime.fromISO(departureBack).toFormat(dateFormats.DETAILED_DATE)
    : undefined
  const ticketOutside = cn('h-full max-w-full lg:h-24 flex flex-row justify-center rounded border bg-neutral-lightest', {
    'border-neutral': disabled,
    'bg-white border-primary-base': !disabled,
  })
  const ticketBody = cn(
    'grid items-start lg:justify-between h-full p-5 rounded-lg font-bold grid-areas lg:grid-ticket bg-neutral-lightest',
    {
      'text-neutral': disabled,
      'bg-white text-primary-base': !disabled,
    }
  )
  
  const { onDownload, onCancel, onViewClick, iframeProps } = useActionsButtonHandlers(
    reservationId,
    reservation.token || ''
  )

  return (
    <main className={ticketOutside}>
      <section className="w-full">
        <div className={ticketBody}>
          <KebabMenu className= 'grid-area-kebab' >
            <KebabButton onClick={onViewClick}>{getTranslation('profileButtonView')}</KebabButton>

            {!disabled && (
              <>
                <KebabButton onClick={onDownload}>{getTranslation('ticketActionButtonsDownload')}</KebabButton>
                {isCancelable && (
                  <KebabButton onClick={onCancel}>{getTranslation('ticketActionButtonsCancel')}</KebabButton>
                )}
              </>
            )}
          </KebabMenu>

          <div className="mb-7 flex flex-col items-start grid-area-details lg:mb-0 lg:items-center">
            <span className="text-xs">{getTranslation('ticketPrintedTicketNumber')}</span>
            <span className="text-lg">{reservationId}</span>
          </div>

          <div className="mb-7 flex flex-col items-start text-center text-sm grid-area-info lg:mb-0 lg:items-center">
            <span>{departureValueThere}</span>
            <span>{departureValueBack}</span>
          </div>

          <div className="mb-7 flex flex-col text-sm grid-area-ports lg:mb-0">
            <span>
              {departurePortThere} - {arrivalPortThere}
            </span>
            {departurePortBack && arrivalPortBack && (
              <span>
                {departurePortBack} - {arrivalPortBack}
              </span>
            )}
          </div>

          <div className="flex flex-col gap-2 text-right text-sm grid-area-owner">
            <div className="flex items-center">
              <span className="mr-2">
                <UserIcon />
              </span>
              <span>{reservationOwner?.fullName}</span>
            </div>
            <div className="flex items-center text-sm">
              <span className="mr-2">
                <Eticket />
              </span>
              <span>{getTranslation('ticketPrintedEticket')}</span>
            </div>
          </div>
        </div>
        <iframe {...iframeProps} />
      </section>
    </main>
  )
}

export default Reservation
