import { FC, HTMLAttributes } from 'react'
import { TableHeaderCell } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/CustomersTable/TableHeaderCell'
import cn from 'classnames'

interface TabsWithLabelProps extends HTMLAttributes<HTMLDivElement> {
  labels: string[]
}

const TableHeader: FC<TabsWithLabelProps> = ({ labels, className }) => {
  return (
    <div className={cn('mb-5 grid font-bold h-12 w-full rounded-xl bg-neutral-lightest', className)}>
      {labels.map((label) => (
        <TableHeaderCell key={label} title={label} />
      ))}
    </div>
  )
}

export default TableHeader
