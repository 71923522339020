import { salesStaticService } from '@/root/shared-api'
import useSWR from 'swr/immutable'
import { BoraTheme, Slug } from '@/root/shared-types'
import { getStyledHTML } from '@/root/utils/getStyledHTML'
import { useCustomRouter } from '@/root/shared-hooks'

export function useGetPageContent(slug: Slug, theme: BoraTheme) {
  const { locale } = useCustomRouter()
  const {
    data: page,
    error,
    isLoading,
  } = useSWR([`/pages/${locale}`, slug], () => salesStaticService.getPages(locale, slug))

  const slugs = ['news', 'tariffs', 'schedule']

  if (!slugs.includes(slug) && page && 'body' in page) {
    page.body = page && getStyledHTML({ body: page.body, slug, theme })
  }

  return {
    pageContent: { slug, page },
    error,
    isLoading,
  }
}
