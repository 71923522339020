import { FC } from 'react'
import { Customer as CustomerProps, Group } from '@/root/shared-types'
import { GroupBadge, KebabButton } from '@/root/ui/admaritime'
import ProfileButton from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/ProfileButon/ProfileButton'
import { useCreateOrEditFamily, useGetTranslations, useModal } from '@/root/shared-hooks'
import { ProfilePopUp } from '@/root/components/admaritime/ProfilePopUp/ProfilePopUp'
import { RowContainer } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/RowContainer/RowContainer'
import { TableCell } from '@/root/modules/admaritime/CustomerInformation/PassengersAndGroups/components/TableCell/TableCell'
import { KebabMenu } from '@/root/components/admaritime'
import { formatedDateToAnotherFormat } from '@/root/shared-helpers'

interface CustomerPropsWithGroups extends CustomerProps {
  groups: Group[]
  isOwner?: boolean
}

const Customer: FC<CustomerPropsWithGroups> = ({ groups, isOwner, ...customer }) => {
  const { fullName, birthday, companies, customerId } = customer
  const { getTranslation } = useGetTranslations()
  const { setModalBody } = useModal()
  const { deletePassenger } = useCreateOrEditFamily()

  return (
    <RowContainer className="grid-cols-[25%_25%_45%_5%]">
      <TableCell className="w-full whitespace-normal break-all">
        <span className="flex flex-wrap gap-1">
          {fullName} {isOwner && <p>({getTranslation('profileOwnerMe')})</p>}
        </span>
      </TableCell>
      <TableCell>{birthday && formatedDateToAnotherFormat(birthday, 'dd-MM-yyyy')}</TableCell>
      <span className="flex flex-wrap gap-2">
        {companies && companies.length > 0 ? (
          companies.map(({ legalName, companyId }) => <GroupBadge title={legalName} key={companyId} />)
        ) : (
          <ProfileButton
            onClick={() =>
              setModalBody(
                <ProfilePopUp type="addToGroup" customerId={customerId} groups={groups} customer={customer} />
              )
            }
            label={getTranslation('profileAddToGroupPopUpTitle')}
            className="text-normal h-10"
          />
        )}
      </span>
      <div className="flex cursor-pointer justify-center">
        <KebabMenu>
          <KebabButton
            onClick={() => setModalBody(<ProfilePopUp type="editPassenger" groups={groups} customer={customer} />)}
          >
            {getTranslation('profileButtonEdit')}
          </KebabButton>
          {!isOwner && (
            <KebabButton onClick={() => deletePassenger(customerId)}>
              {getTranslation('profileButtonRemove')}
            </KebabButton>
          )}
        </KebabMenu>
      </div>
    </RowContainer>
  )
}

export default Customer
