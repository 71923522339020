import useSWR from 'swr'
import axios from 'axios'
import { DateTime } from 'luxon'
import { dateFormats } from '@/root/shared-consts'

interface UseGetAvailableDatesProps {
  sailPackage?: string
}

export const useGetAvailableDates = ({ sailPackage }: UseGetAvailableDatesProps): Array<Date> => {
  const today = DateTime.now().toFormat(dateFormats.ISO_DATE)
  const getDates = async (): Promise<Array<string> | null> => {
    if (!sailPackage) return []
    const { data } = await axios({
      url: `/api/${today}/${sailPackage}`,
    })
    return data
  }

  const { data } = useSWR(sailPackage ? `get-dates ${sailPackage} ${today}` : null, getDates, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  })

  return data ? data.map((date) => new Date(date)) : []
}
